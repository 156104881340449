import { ActionEdit } from '@robotsnacks/icons';
import { ToolbarButton } from '@robotsnacks/ui';
import React, { SFC } from 'react';
import BlockToolbar, { BlockToolbarProps } from '../BlockToolbar';
import DeleteBlockFlyout from '../DeleteBlockFlyout';

type Props = { onDeleteClick?: () => void; onEditClick?: () => void } & Pick<
  BlockToolbarProps,
  'block' | 'parentItems'
>;

const TITLE = 'Code';

const CodeToolbar: SFC<Props> = ({ onDeleteClick, onEditClick, ...rest }) => (
  <BlockToolbar title={TITLE} {...rest}>
    <ToolbarButton onClick={onEditClick}>
      <ActionEdit />
    </ToolbarButton>
    <DeleteBlockFlyout onDeleteClick={onDeleteClick} title={TITLE} />
    {/* <ToolbarSeparator />
    <ToolbarButton>
      <Icon>action:settings</Icon>
    </ToolbarButton> */}
  </BlockToolbar>
);

export default CodeToolbar;
