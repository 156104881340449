/* tslint:disable */

import { ActionSettings } from '@robotsnacks/icons';
import { noop } from 'lodash';
import React, { Component } from 'react';

import {
  ToolbarFlyout,
  WithStyles,
  createStyles,
  withStyles,
} from '@robotsnacks/ui';

const styles = createStyles<'root'>(theme => ({
  root: {
    fontSize: 13,
    margin: '0.5rem',
    padding: 2,
    whiteSpace: 'nowrap',
    textAlign: 'left',
    width: '250px',
    '& strong': {
      fontWeight: 700,
    },
    '& select, & input, & textarea': {
      display: 'block',
      padding: '2px',
      width: 'calc(100% - 8px)',
    },
    '& select': {
      width: '100%',
    },
    '& > div': {
      margin: '0.5rem 0',
      position: 'relative',
    },
    '& label > span': {
      display: 'block',
      paddingBottom: '0.5rem',
    },
  },
}));

export interface ImageOptionsFlyoutProps {
  altText?: string;
  format?: string;
  link?: string;
  onAltTextChange?: (altText: string) => void;
  onFormatChange?: (format: string) => void;
  onLinkChange?: (link: string) => void;
  onPinterestDescriptionChange?: (description: string) => void;
  pinterestDescription?: string;
}

type Props = WithStyles<ImageOptionsFlyoutProps, typeof styles> &
  typeof defaultProps;

type State = {
  in?: boolean;
};

const defaultProps = Object.freeze({
  onDeleteClick: noop,
});

const initialState = Object.freeze({
  in: false,
});

class ImageOptionsFlyout extends Component<Props, State> {
  static defaultProps = defaultProps;
  state = initialState;

  render() {
    const {
      altText,
      classes,
      format,
      link,
      onAltTextChange = noop,
      onFormatChange,
      onLinkChange = noop,
      onPinterestDescriptionChange,
      pinterestDescription,
    } = this.props;
    const flyout = (
      <div className={classes.root}>
        <div>
          <label>
            <span>Image Format</span>
            <select
              value={format}
              onChange={e => onFormatChange(e.currentTarget.value)}
            >
              <option value="full_width">Full Width</option>
              <option value="content_width">Content Width</option>
            </select>
          </label>
        </div>
        <div>
          <label>
            <span>Alt Text</span>
            <input
              type="text"
              value={altText}
              onChange={e => onAltTextChange(e.currentTarget.value)}
            />
          </label>
        </div>
        <div>
          <label>
            <span>Link (leave blank to remove link)</span>
            <input
              type="text"
              placeholder="https://..."
              value={link}
              onChange={e => onLinkChange(e.currentTarget.value)}
            />
          </label>
        </div>
        <div>
          <label>
            <span>Pinterest Description</span>
            <textarea
              value={pinterestDescription}
              onChange={e =>
                onPinterestDescriptionChange(e.currentTarget.value)
              }
            />
          </label>
        </div>
      </div>
    );

    return (
      <ToolbarFlyout
        flyout={flyout}
        in={this.state.in}
        onFocus={this._handleFocus}
        onBlur={this._handleBlur}
      >
        <ActionSettings />
      </ToolbarFlyout>
    );
  }

  private _handleFocus = () => {
    this.setState({ in: true });
  };

  private _handleBlur = () => {
    this.setState({ in: false });
  };
}

export default withStyles(styles)(ImageOptionsFlyout);
