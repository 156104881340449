import React, { Component } from 'react';
import { BlockComponentProps } from '../BlockComponent';
import { CodeBlockAttributes } from './CodeBlockAttributes';
import CodeWrapper from './CodeWrapper';

type Props = BlockComponentProps<CodeBlockAttributes>;

export default class WritableCode extends Component<Props> {
  public render() {
    const { block } = this.props;
    return (
      <div
        id={block.getKey()}
        style={{ position: 'relative', flex: 1, display: 'flex' }}
      >
        <CodeWrapper readonly block={block} />
      </div>
    );
  }
}
