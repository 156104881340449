import { WithStyles, createStyles, cx, withStyles } from '@robotsnacks/ui';
import React, { HTMLAttributes, SFC } from 'react';

const styles = createStyles<'root'>(theme => ({
  root: {
    left: 0,
    position: 'absolute',
    top: -12,
    zIndex: 50,
  },
}));

export interface ToolbarWrapperProps extends HTMLAttributes<HTMLDivElement> {}

type Props = WithStyles<ToolbarWrapperProps, typeof styles>;

const ToolbarWrapper: SFC<Props> = ({ classes, className, theme, ...rest }) => (
  <div className={cx(classes.root, className)} {...rest} />
);

export default withStyles(styles)(ToolbarWrapper);
