import { CodeBlock } from '@robotsnacks/icons';
import React from 'react';

export default {
  attributes: {
    html: { type: 'identity' },
    source: { type: 'identity' },
  },
  icon: <CodeBlock />,
  name: 'CODE',
  title: 'Code',
  v: 0,
};
