/* tslint:disable */

import { Calendar } from '@robotsnacks/icons';
import React, { Component, Fragment } from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { DateTime, Duration } from 'luxon';
import { debounce, get, noop } from 'lodash';

import {
  Button,
  Input,
  ListPicker,
  ToolbarFlyout,
  WithStyles,
  createStyles,
  withStyles,
} from '@robotsnacks/ui';

const query = gql`
  query events($query: String) {
    site {
      id
    }
    events(first: 40, query: $query) {
      edges {
        node {
          id
          name
          description
          page {
            id
            path
          }
          images {
            type
            image {
              id
            }
          }
          eventOccurrences(first: 40) {
            edges {
              node {
                id
                startsAt
                duration
              }
            }
          }
        }
      }
    }
  }
`;

const convertEventList = (data: any) =>
  get(data, 'events.edges', []).map((e: any) => ({
    description: e.node.description,
    id: e.node.id,
    imageId: get(e.node, 'images[0].image.id'),
    title: e.node.name,
    start: get(e.node, 'eventOccurrences.edges[0].startsAt')
      ? DateTime.fromISO(get(e.node, 'eventOccurrences.edges[0].startsAt'))
      : undefined,
    duration: get(e.node, 'eventOccurrences.edges[0].duration')
      ? Duration.fromISO(get(e.node, 'eventOccurrences.edges[0].duration'))
      : undefined,
    page: e.node.page,
    times: get(e.node, 'eventOccurrences.edges', []).map(({ node }) => ({
      id: node.id,
      start: DateTime.fromISO(node.startsAt),
      duration: Duration.fromMillis(node.duration ? node.duration * 1000 : 0),
    })),
  }));

const expandEventList = (eventList: any) => {
  return eventList.reduce((curr: any, event: any) => {
    return [
      ...curr,
      ...(event.times || []).map((time: any) => {
        return {
          key: time.id,
          label: `${event.title} - ${time.start.toLocaleString(
            DateTime.DATETIME_SHORT,
          )}`,
          value: {
            imageId: event.imageId,
            id: event.id,
            title: event.title,
            description: event.description,
            start: time.start,
            duration: time.duration,
            page: event.page,
          },
        };
      }),
    ];
  }, []);
};

class EventListFlyout extends Component<any, any> {
  public state: any = {};

  render() {
    const flyout = (
      <div>
        <div style={{ padding: '0.5rem' }}>
          <input
            onChange={e => this._handleSearchChange(e.currentTarget.value)}
            placeholder="Search"
            style={{
              border: 'none',
              borderBottom: '1px solid #eee',
              outline: 'none',
              width: '100%',
            }}
          />
        </div>
        <Query
          query={query}
          variables={{ query: this.state.query }}
          fetchPolicy="cache-and-network"
        >
          {({ data, ...rest }) => {
            const eventList = convertEventList(data);
            return (
              <ListPicker
                onSelect={this._handleSelect}
                options={expandEventList(eventList)}
              />
            );
          }}
        </Query>
      </div>
    );

    return (
      <ToolbarFlyout
        flyout={flyout}
        in={this.state.in}
        onFocus={this._handleFocus}
        onBlur={this._handleBlur}
      >
        <Calendar />
      </ToolbarFlyout>
    );
  }

  private _handleSelect = (page: any) => {
    if (this.props.onSelect) {
      this.props.onSelect(page);
    }
    this.setState({ in: false });
  };

  private _handleFocus = () => {
    this.setState({ in: true });
  };

  private _handleBlur = () => {
    this.setState({ in: false });
  };

  private _handleSearchChange = debounce((query: string) => {
    this.setState({ query });
  });
}

export default EventListFlyout;
