import { CardsetterMarkSvg } from './svg';
import { makeSvgAsset } from './make-svg-asset';
import styled from 'styled-components';

export const CardsetterMark = styled(
  makeSvgAsset(CardsetterMarkSvg, 'CardsetterMark'),
)`
  position: relative;
  top: 0.1em;

  rect {
    stroke: currentColor;
  }
  circle {
    fill: currentColor;
  }
`;
