import { CreateStoreOptions, createStore } from './create-store';
import {
  receiveLocationChangeFromAdmin,
  sendViewportLocationChangeMiddleware,
} from '../middleware';
import { PublicAppAction } from '../actions';
import { PublicAppState } from '../state';
import { Store } from 'redux';
import { createPublicAppReducer } from '../reducers';
import { publicRootEpic } from '../epics';

/**
 * Create admin app store options object.
 */
export type CreatePublicAppStoreOptions = Omit<
  CreateStoreOptions<PublicAppState, PublicAppAction>,
  'rootReducer' | 'name'
>;

/**
 * Creates a new Admin App Redux store for the client side.
 * @param options Create store options object.
 */
export const createPublicAppStore = (
  options: CreatePublicAppStoreOptions,
): Store<PublicAppState, PublicAppAction> => {
  const rootReducer = createPublicAppReducer({ history: options.history });

  const [store, epicMiddleware] = createStore({
    ...options,
    rootReducer,
    middleware: [
      receiveLocationChangeFromAdmin(),
      sendViewportLocationChangeMiddleware(),
    ],
    name: 'Public App',
  });

  epicMiddleware.run(publicRootEpic as any);

  return store;
};
