import { Button, ButtonRow, Popup } from '@robotsnacks/ui';
import { DateTime, Duration } from 'luxon';
import React, { Component } from 'react';
import FeedBuilderPopup from './FeedBuilderPopup';

type Props = {
  // onCreateEvent: (event: any) => void;
  onSaveClick: (def: any) => void;
};

interface State {
  popup?: 'create' | 'select';
}

class FeedBlockPlaceholder extends Component<Props, State> {
  public state: State = {};

  public render() {
    return (
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
          minHeight: '300px',
        }}
      >
        <ButtonRow style={{ textAlign: 'center' }}>
          <Popup
            in={this.state.popup === 'select'}
            popup={
              <FeedBuilderPopup
                onSaveClick={(def: any) => {
                  this.props.onSaveClick(def);
                  this.setState({ popup: undefined });
                }}
              />
            }
            style={{ zIndex: 100 }}
          >
            {({ popup }) => (
              <Button
                onClick={() =>
                  this.setState({
                    popup: this.state.popup ? undefined : 'select',
                  })
                }
              >
                Select Content {popup}
              </Button>
            )}
          </Popup>
        </ButtonRow>
      </div>
    );
  }
}

export default FeedBlockPlaceholder;
