import { get } from 'lodash';
import React, { Component, ReactNode } from 'react';
import { Query } from 'react-apollo';
import Block from '../Block';
import { BlockComponentProps } from '../BlockComponent';
import { FeedBlockAttributes } from './FeedBlockAttributes';
import FeedGrid from './FeedGrid';
import FeedList from './FeedList';
import resolveFeed from './query';
import resolveCql from './resolve-cql';

export interface FeedBlockProps
  extends BlockComponentProps<FeedBlockAttributes> {
  onDelete?: (block: Block<FeedBlockAttributes>) => void;
  parentToolbar?: ReactNode;
  parentToolbarItems?: ReactNode;
  defaults?: any;
  imageClient?: any;
}

type Props = FeedBlockProps;

export default class ReadOnlyFeedBlock extends Component<Props> {
  public shouldComponentUpdate() {
    return false;
  }

  public render() {
    const { block } = this.props;
    const flow = block.getAttribute('flow');

    if (!flow) {
      return null;
    }

    return this._renderFeed();
  }

  private _renderFeed() {
    const { block } = this.props;
    const cql = (block.getAttribute('cql') || resolveCql(block)).trim();
    return (
      <Query query={resolveFeed} variables={{ cql }}>
        {({ data, loading }) => {
          if (loading) {
            return null;
          }

          const feed = get(data, 'feed.edges', []).map(
            (edge: any) => edge.node,
          );

          const flow = this.props.block.getAttribute('flow');

          if (flow === 'list') {
            return <FeedList block={block} feed={feed} />;
          } else {
            return (
              <FeedGrid
                feed={feed}
                flow={this.props.block.getAttribute('flow')}
                block={this.props.block}
                breakpointNames={this.props.breakpointNames}
                breakpoints={this.props.breakpoints}
                currentBreakpoint={this.props.currentBreakpoint}
                defaults={this.props.defaults}
                getBreakpointMedia={this.props.getBreakpointMedia}
                imageClient={this.props.imageClient}
              />
            );
          }
        }}
      </Query>
    );
  }
}
