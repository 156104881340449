import { ActionDelete } from '@robotsnacks/icons';
import { noop } from 'lodash';
import React, { Component, ReactNode } from 'react';

import {
  Button,
  ButtonRow,
  ToolbarFlyout,
  WithStyles,
  createStyles,
  withStyles,
} from '@robotsnacks/ui';

const styles = createStyles<'root'>(theme => ({
  root: {
    fontSize: 13,
    padding: 2,
    whiteSpace: 'nowrap',
    textAlign: 'center',
    '& p': {
      marginBottom: '0.5em',
    },
    '& strong': {
      fontWeight: 700,
    },
  },
}));

export interface DeleteBlockFlyoutProps {
  title: ReactNode;
  onDeleteClick?: () => void;
}

type Props = WithStyles<DeleteBlockFlyoutProps, typeof styles> &
  typeof defaultProps;

type State = {
  in?: boolean;
};

const defaultProps = Object.freeze({
  onDeleteClick: noop,
});

const initialState = Object.freeze({
  in: false,
});

class DeleteBlockFlyout extends Component<Props, State> {
  static defaultProps = defaultProps;
  state = initialState;

  render() {
    const { classes } = this.props;
    const flyout = (
      <div className={classes.root}>
        <p>
          Delete this <strong>{this.props.title}</strong> block?
        </p>
        <ButtonRow>
          <Button onClick={this._handleCancelClick}>Cancel</Button>
          <Button onClick={this._handleDeleteClick} type="error">
            Delete
          </Button>
        </ButtonRow>
      </div>
    );

    return (
      <ToolbarFlyout
        flyout={flyout}
        in={this.state.in}
        onFocus={this._handleFocus}
        onBlur={this._handleBlur}
      >
        <ActionDelete />
      </ToolbarFlyout>
    );
  }

  private _handleCancelClick = () => {
    this.setState({ in: false });
  };

  private _handleDeleteClick = () => {
    this.setState({ in: false });
    this.props.onDeleteClick();
  };

  private _handleFocus = () => {
    this.setState({ in: true });
  };

  private _handleBlur = () => {
    this.setState({ in: false });
  };
}

export default withStyles(styles)(DeleteBlockFlyout);
