import { LoginSuccessAction } from '../actions';

const KEY = 'cardsetter:auth';

export interface PersistedAuth {
  accessToken: string;
  expiresAt: Date;
  refreshToken: string;
  tokenType: string;
}

export const persistAuth = (
  item: Pick<
    LoginSuccessAction,
    'accessToken' | 'expiresIn' | 'refreshToken' | 'tokenType'
  >,
): void => {
  const { accessToken, expiresIn, refreshToken, tokenType } = item;
  const expiresAt = new Date(Date.now() + expiresIn * 1000).toISOString();
  localStorage.setItem(
    KEY,
    JSON.stringify({
      accessToken,
      expiresAt,
      refreshToken,
      tokenType,
    }),
  );
};

export const retrieveAuth = (): PersistedAuth | undefined => {
  const item = localStorage.getItem(KEY);

  if (item) {
    const { accessToken, expiresAt, refreshToken, tokenType } = JSON.parse(
      item,
    );

    const now = new Date();

    if (new Date(expiresAt) < now) {
      return;
    }

    return {
      accessToken,
      expiresAt: new Date(expiresAt),
      refreshToken,
      tokenType,
    };
  }
};
