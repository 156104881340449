/* tslint:disable */
import {
  Container,
  WithStyles,
  createStyles,
  cx,
  withStyles,
} from '@robotsnacks/ui';
import React, { HTMLAttributes, SFC } from 'react';

const styles = createStyles<'root'>(() => ({
  root: {
    boxSizing: 'border-box',
    flex: 1,
    maxWidth: '100%',
    position: 'relative',
  },
  img: {
    maxWidth: '100%',
  },
}));

export interface ImageBlockWrapperProps extends HTMLAttributes<HTMLDivElement> {
  format?: string;
}

type Props = WithStyles<ImageBlockWrapperProps, typeof styles>;

const ImageBlockWrapper: SFC<Props> = ({
  classes,
  children,
  theme,
  format,
  ...rest
}) => {
  const Wrapper = format === 'content_width' ? Container : React.Fragment;
  const props = format === 'content_width' ? { style: { padding: 0 } } : {};
  return (
    <div className={cx(classes.root, 'cs-image')} {...rest}>
      <Wrapper {...props}>{children}</Wrapper>
    </div>
  );
};
export default withStyles(styles)(ImageBlockWrapper);
