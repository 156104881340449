import { ToolbarSeparator } from '@robotsnacks/ui';
import React, { SFC } from 'react';
import BlockToolbar, { BlockToolbarProps } from '../BlockToolbar';
import DeleteBlockFlyout from '../DeleteBlockFlyout';
import CardOptionsFlyout from './CardOptionsFlyout';
import PageListFlyout from './PageListFlyout';

type TmpPage = {
  description?: string;
  id: string;
  imageId?: string;
  title: string;
  to: string;
};

interface Props extends Pick<BlockToolbarProps, 'block' | 'parentItems'> {
  onDeleteClick?: () => void;
  onLinkSelect?: (page: TmpPage | string) => void;
  onShowSponsorChange?: (showSponsor: boolean) => void;
  showSponsor?: boolean;
}

const TITLE = 'Card';

const CardToolbar: SFC<Props> = ({
  onDeleteClick,
  onLinkSelect,
  showSponsor,
  onShowSponsorChange,
  ...rest
}) => (
  <BlockToolbar title={TITLE} {...rest}>
    <PageListFlyout
      href={(rest as any).block.getAttribute('href')}
      onSelect={onLinkSelect}
      onSaveClick={(h: string) => onLinkSelect!(h)}
    />
    <CardOptionsFlyout
      showSponsor={showSponsor}
      onShowSponsorChange={onShowSponsorChange}
    />
    <ToolbarSeparator />
    <DeleteBlockFlyout onDeleteClick={onDeleteClick} title={TITLE} />
    {/* <ToolbarSeparator />
    <ToolbarButton>
      <Icon>action:settings</Icon>
    </ToolbarButton> */}
  </BlockToolbar>
);

export default CardToolbar;
