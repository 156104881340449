/* tslint:disable */

import { ActionSettings, ActionClose } from '@robotsnacks/icons';
import { head, tail, noop } from 'lodash';
import React, { Component } from 'react';

import {
  ToolbarFlyout,
  WithStyles,
  createStyles,
  withStyles,
} from '@robotsnacks/ui';

import {
  SnipcartBlockAttributes,
  SnipcartItem,
  SnipcartItemCustomField,
  SnipcartItemCustomFieldOption,
} from './SnipcartBlockAttributes';

const styles = createStyles<'root'>(theme => ({
  root: {
    fontSize: 13,
    margin: '0.5rem',
    padding: 2,
    whiteSpace: 'nowrap',
    textAlign: 'center',
    width: '600px',
    '& strong': {
      fontWeight: 700,
    },
    '& select': {
      display: 'block',
      marginTop: '4px',
      width: '100%',
    },
    '& label': {
      textAlign: 'left',
      display: 'block',
      padding: '4px',
    },
    '& input': {
      display: 'block',
      marginTop: '4px',
      width: 'calc(100% - 4px)',
    },
  },
}));

export interface SnipcartOptionsFlyoutProps {
  attributes?: SnipcartBlockAttributes;
  onChange?: (attr: SnipcartBlockAttributes) => void;
}

type Props = WithStyles<SnipcartOptionsFlyoutProps, typeof styles> &
  typeof defaultProps;

type State = {
  in?: boolean;
};

const defaultProps = Object.freeze({
  onDeleteClick: noop,
});

const initialState = Object.freeze({
  in: false,
});

const ItemInput: React.FunctionComponent<any> = props => {
  return (
    <div style={{ display: 'flex' }}>
      <label style={{ flex: 1 }}>
        ID
        <input
          value={props.item.itemId}
          onChange={v =>
            props.onChange({ ...props.item, itemId: v.currentTarget.value })
          }
        />
      </label>
      <label style={{ flex: 1 }}>
        Name
        <input
          value={props.item.itemName}
          onChange={v =>
            props.onChange({ ...props.item, itemName: v.currentTarget.value })
          }
        />
      </label>
      <label style={{ flex: 2 }}>
        Description
        <input
          value={props.item.itemDescription}
          onChange={v =>
            props.onChange({
              ...props.item,
              itemDescription: v.currentTarget.value,
            })
          }
        />
      </label>
      <label style={{ flex: 1 }}>
        Price
        <input
          value={props.item.itemPrice}
          onChange={v =>
            props.onChange({
              ...props.item,
              itemPrice: v.currentTarget.value,
            })
          }
        />
      </label>
    </div>
  );
};

const CustomFieldOptionsInput: React.FunctionComponent<any> = props => {
  return (
    <div style={{ display: 'flex' }}>
      <label style={{ flex: 2 }}>
        Option (e.g. "Medium")
        <input
          value={props.option.name}
          onChange={v =>
            props.onChange({ ...props.option, name: v.currentTarget.value })
          }
        />
      </label>
      <label style={{ flex: 1 }}>
        Price Change
        <input
          value={props.option.priceChange}
          type="number"
          onChange={v =>
            props.onChange({
              ...props.option,
              priceChange: v.currentTarget.value,
            })
          }
        />
      </label>
      <div>
        <ActionClose
          onClick={props.onDeleteClick}
          style={{ cursor: 'pointer', fontSize: '30px', marginTop: '16px' }}
        />
      </div>
    </div>
  );
};

export const EMPTY_ITEM: SnipcartItem = Object.freeze({
  customFields: [],
  itemDescription: '',
  itemId: '',
  itemName: '',
  itemPrice: 0,
});

const EMPTY_CUSTOM_FIELD: SnipcartItemCustomField = Object.freeze({
  name: '',
  options: [],
  required: false,
});

const EMPTY_CUSTOM_FIELD_OPTION: SnipcartItemCustomFieldOption = Object.freeze({
  name: '',
  priceChange: 0,
});

class SnipcartOptionsFlyout extends Component<Props, State> {
  static defaultProps = defaultProps;
  state = initialState;

  render() {
    const { classes, onChange } = this.props;
    const item: SnipcartItem = this.props.attributes.item || EMPTY_ITEM;
    const customFields = item.customFields || [];
    const flyout = (
      <div className={classes.root}>
        <ItemInput
          item={item}
          onChange={(v: any) =>
            onChange({
              item: {
                ...v,
                customFields: item.customFields,
              },
            })
          }
        />
        {customFields.map((customField, i) => {
          const options =
            customField.options && customField.options.length > 0
              ? customField.options
              : [EMPTY_CUSTOM_FIELD_OPTION];
          return (
            <div
              key={i}
              style={{
                padding: '4px',
                margin: '8px 0',
                border: '1px solid #f2f2f2',
                background: '#f8f8f8',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '2px',
                }}
              >
                <div style={{ paddingLeft: '4px' }}>Variant Name:</div>
                <input
                  style={{
                    display: 'inline-block',
                    flex: 1,
                    marginLeft: '4px',
                    marginRight: '4px',
                    width: 'auto',
                  }}
                  type="text"
                  value={customField.name}
                  onChange={e => {
                    const newCustomFields = customFields.slice(0);
                    newCustomFields.splice(i, 1, {
                      ...customField,
                      name: e.currentTarget.value,
                    });
                    onChange({
                      item: { ...item, customFields: newCustomFields },
                    });
                  }}
                />
              </div>
              <div style={{ margin: '2px 0' }}>
                {options.map((option, j) => {
                  return (
                    <CustomFieldOptionsInput
                      key={j}
                      option={option}
                      onChange={(o: any) => {
                        const newOptions = options.slice();
                        newOptions.splice(j, 1, o);
                        const newCustomFields = customFields.slice();
                        newCustomFields.splice(i, 1, {
                          ...customField,
                          options: newOptions,
                        });
                        onChange({
                          item: { ...item, customFields: newCustomFields },
                        });
                      }}
                      onDeleteClick={() => {
                        const newOptions = options.slice();
                        newOptions.splice(j, 1);
                        const newCustomFields = customFields.slice();
                        newCustomFields.splice(i, 1, {
                          ...customField,
                          options: newOptions,
                        });
                        onChange({
                          item: { ...item, customFields: newCustomFields },
                        });
                      }}
                    />
                  );
                })}
              </div>
              <div>
                <button
                  style={{ marginRight: '4px' }}
                  onClick={() => {
                    const newOptions = options.slice();
                    newOptions.push(EMPTY_CUSTOM_FIELD_OPTION);
                    const newCustomFields = customFields.slice();
                    newCustomFields.splice(i, 1, {
                      ...customField,
                      options: newOptions,
                    });
                    onChange({
                      item: { ...item, customFields: newCustomFields },
                    });
                  }}
                >
                  Add Option
                </button>
                <button
                  style={{ marginLeft: '4px' }}
                  onClick={() => {
                    const newOptions = options.slice();
                    newOptions.push(EMPTY_CUSTOM_FIELD_OPTION);
                    const newCustomFields = customFields.slice();
                    newCustomFields.splice(i, 1);
                    onChange({
                      item: { ...item, customFields: newCustomFields },
                    });
                  }}
                >
                  Remove Variant
                </button>
              </div>
            </div>
          );
        })}
        <button
          style={{ marginTop: '0.5rem' }}
          onClick={() =>
            onChange({
              item: {
                ...item,
                customFields: [...customFields, EMPTY_CUSTOM_FIELD],
              },
            })
          }
        >
          Add Variant
        </button>
      </div>
    );

    return (
      <ToolbarFlyout
        flyout={flyout}
        in={this.state.in}
        onFocus={this._handleFocus}
        onBlur={this._handleBlur}
      >
        <ActionSettings />
      </ToolbarFlyout>
    );
  }

  private _handleFocus = () => {
    this.setState({ in: true });
  };

  private _handleBlur = () => {
    this.setState({ in: false });
  };
}

export default withStyles(styles)(SnipcartOptionsFlyout);
