import { ApolloProvider } from 'react-apollo';
import { ClientAppProps } from './client-app-props';
import { ConnectedRouter } from 'connected-react-router';
import { HeadProvider } from 'react-head';
import { Provider } from 'react-redux';
import React from 'react';

export const ClientApp: React.FunctionComponent<ClientAppProps> = (
  props,
): React.ReactElement => {
  return (
    <Provider store={props.store}>
      <HeadProvider>
        <ConnectedRouter history={props.history}>
          <ApolloProvider client={props.client}>
            {props.children}
          </ApolloProvider>
        </ConnectedRouter>
      </HeadProvider>
    </Provider>
  );
};
