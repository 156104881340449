/* eslint disable */

import { ActionSettings } from '@robotsnacks/icons';
import { noop } from 'lodash';
import React, { Component } from 'react';

import {
  Button,
  ToolbarFlyout,
  WithStyles,
  createStyles,
  withStyles,
} from '@robotsnacks/ui';

const styles = createStyles<'root'>(theme => ({
  root: {
    fontSize: 13,
    margin: '0.5rem',
    padding: 2,
    whiteSpace: 'nowrap',
    textAlign: 'left',
    width: '250px',
    '& strong': {
      fontWeight: 700,
    },
    '& select, & input': {
      display: 'block',
      padding: '2px',
      width: 'calc(100% - 8px)',
    },
    '& select': {
      width: '100%',
    },
    '& > div': {
      margin: '0.5rem 0',
      position: 'relative',
    },
    '& label > span': {
      display: 'block',
      paddingBottom: '0.5rem',
    },
  },
}));

export interface FeedOptionsFlyoutProps {
  cql?: string;
  onCqlChange?: (cql: string) => void;
}

type Props = WithStyles<FeedOptionsFlyoutProps, typeof styles> &
  typeof defaultProps;

type State = {
  in?: boolean;
  tmpCql?: string;
};

const defaultProps = Object.freeze({
  onDeleteClick: noop,
});

const initialState = Object.freeze({
  in: false,
  tmpCql: '',
});

const getInitialState = (props: Props) => ({
  in: false,
  tmpCql: props.cql || '',
});

class FeedOptionsFlyout extends Component<Props, State> {
  static defaultProps = defaultProps;
  state = getInitialState(this.props);

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
  ): void {
    if (prevProps.cql !== this.props.cql) {
      this.setState({ tmpCql: this.props.cql });
    }
  }

  render() {
    const { cql, classes } = this.props;
    const flyout = (
      <div className={classes.root}>
        <div>
          <label>
            <span>Query</span>
            <input
              type="text"
              value={this.state.tmpCql}
              onChange={this._handleCqlChange}
            />
          </label>
          <div style={{ marginTop: '6px' }}>
            <Button onClick={this._handleSaveClick}>Save</Button>
          </div>
        </div>
      </div>
    );

    return (
      <ToolbarFlyout
        flyout={flyout}
        in={this.state.in}
        onFocus={this._handleFocus}
        onBlur={this._handleBlur}
      >
        <ActionSettings />
      </ToolbarFlyout>
    );
  }

  private _handleFocus = () => {
    this.setState({ in: true });
  };

  private _handleBlur = () => {
    this.setState({ in: false });
  };

  private _handleCqlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ tmpCql: e.target.value });
  };

  private _handleSaveClick = () => {
    if (this.props.onCqlChange) {
      this.props.onCqlChange(this.state.tmpCql);
    }
    this.setState({ in: false });
  };
}

export default withStyles(styles)(FeedOptionsFlyout);
