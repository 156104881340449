import { CodeBlock } from '@robotsnacks/icons';
import React from 'react';

export default {
  attributes: {
    item: { type: 'identity' },
  },
  icon: <CodeBlock />,
  name: 'SNIPCART',
  title: 'Snipcart',
  v: 0,
};
