import isArrayPath from './isArrayPath';

/**
 *
 * @param path
 */
export default (
  path: string | [string, string] | [string, number],
): [string, string] => {
  let a;

  if (Array.isArray(path)) {
    a = (path as string[]).map(p => p.toString());
  }

  if (typeof path === 'string') {
    a = path.split('.');
  }

  if (!isArrayPath(a)) {
    throw new Error(`Invalid path: ${JSON.stringify(path)}`);
  }

  return a;
};
