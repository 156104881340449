export enum AuthActionType {
  LoginError = 'auth/login/LOGIN_ERROR',
  LoginRequest = 'auth/login/LOGIN_REQUEST',
  LoginSuccess = 'auth/login/LOGIN_SUCCESS',

  ResetPasswordError = 'auth/RESET_PASSWORD_ERROR',
  ResetPasswordRequest = 'auth/RESET_PASSWORD_REQUEST',
  ResetPasswordSuccess = 'auth/RESET_PASSWORD_SUCCESS',

  ChangePasswordError = 'auth/CHANGE_PASSWORD_ERROR',
  ChangePasswordRequest = 'auth/CHANGE_PASSWORD_REQUEST',
  ChangePasswordSuccess = 'auth/CHANGE_PASSWORD_SUCCESS',
}
