/**
 * Generates a random value.
 * @param a Replaced string arg.
 */
const rep = (a: number): string =>
  (a ^ ((Math.random() * 16) >> (a / 4))).toString(16);

/**
 * Generates a UUID.
 */
const uuid = (): string =>
  (([1e7] as any) + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, rep);

/**
 * Generates a block ID.
 */
export default (): string => {
  return `blk_${uuid().replace(/-/g, '')}`;
};
