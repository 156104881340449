import gql from 'graphql-tag';

export const GET_PAGE_QUERY = gql`
  query GetPage($id: ID, $path: String) {
    page(id: $id, path: $path) {
      id
      title
      description
      content
      path
      publishAt

      images {
        image {
          id
          url
        }
        type
      }

      tags {
        id
        name
      }

      url
    }
  }
`;
