import MessageProxy from '../../components/code-editor/message-proxy';

const DEFAULT_URL = '/admin/editor';
const DEFAULT_TARGET = '_blank';

const makeEditorId = () =>
  Math.random()
    .toString(32)
    .slice(2);

const getEditorUri = () => {
  const uri = process.env.EDITOR_URI;
  if (uri && uri.startsWith(':') && typeof window !== 'undefined') {
    const { protocol, hostname } = window.location;
    return `${protocol}//${hostname}${uri}`;
  } else if (uri) {
    return uri;
  }
  return DEFAULT_URL;
};

// eslint-disable-next-line @typescript-eslint/interface-name-prefix
export interface IOpenIdeOptions {
  value?: string;
  url?: string;
  target?: string;
  editorId?: string;
}

export const openIdeWindow = (opts: IOpenIdeOptions = {}) => {
  const options = [
    'width=1000',
    'height=600',
    'menubar=false',
    'toolbar=false',
    'status=false',
    'titlebar=false',
    'close=no',
    'location=false',
    'personalbar=false',
  ];

  const editorId = opts.editorId || makeEditorId();
  const baseUrl = opts.url || getEditorUri();
  const target = opts.target || DEFAULT_TARGET;

  const url = [baseUrl, '?'];
  const q = [`editor_key=${editorId}`];

  if (opts.value) {
    q.push(`value=${encodeURIComponent(opts.value)}`);
  }

  // if (opts.values) {
  //   forEach(opts.values, (value, lang) => {
  //     q.push(`${lang}_value=${encodeURIComponent(value)}`);
  //   });
  // }

  return window.open([...url, q.join('&')].join(''), target, options.join(','));
};

export const openIde = (opts: IOpenIdeOptions = {}) => {
  const editorId = opts.editorId || makeEditorId();
  const window = openIdeWindow({
    ...opts,
    editorId,
  });
  const proxy = new MessageProxy({
    editorKey: editorId,
    win: window,
  });
  return proxy;
};
