import { EventListener, getClientCoordinates } from '@robotsnacks/ui';
import { partial } from 'lodash';
import React, { Component } from 'react';
import { BlockComponentProps } from '../BlockComponent';
import { BlockPickerProps } from '../BlockPicker';
import InsertLine from '../InsertLine';
import Placeholder from '../Placeholder';

const MIN_VISIBLE_DISTANCE = 50;

export interface StackBlockProps
  extends BlockComponentProps,
    Pick<BlockPickerProps, 'blocks'> {}

type Props = StackBlockProps;

type State = {
  clientY?: number;
  activeLineIndex?: number;
};

const defaultProps = Object.freeze({
  blocks: [],
});

const initialState: State = Object.freeze({});

const isHovered = (state: State) => typeof state.clientY === 'number';

const lineIn = (hover: boolean, distances: number[], index: number) => {
  const distance = distances[index];
  return distance < MIN_VISIBLE_DISTANCE && hover;
};

const calculateNearestLineIndex = (distances: number[]) => {
  const min = Math.min(...distances);
  if (min === Infinity) return -1;
  return distances.indexOf(min);
};

export default class Stack extends Component<Props, State> {
  state = initialState;

  private _lines: (HTMLElement | null)[] = [];

  public render() {
    // TODO: come up with an intelligent way to track block keys... right now
    // the last insert line will always blow up.
    // const hover = isHovered(this.state);
    return (
      <EventListener target="window" onMouseMove={this._handleMouseMove}>
        <div>{this._renderStackChildren()}</div>
      </EventListener>
    );
  }

  private _handleMouseMove = (e: MouseEvent) => {
    const { clientY } = getClientCoordinates(e)!;
    this.setState({ clientY });
  };

  private _handleMouseLeave = () => {
    this.setState({ clientY: undefined });
  };

  private _renderStackChildren() {
    const children = this.props.block.getChildren();
    if (children.size > 0) {
      return this._renderChildBlocks();
    } else {
      return this._renderEmptyPlaceholder();
    }
  }

  private _renderEmptyPlaceholder() {
    return (
      <Placeholder
        blocks={this.props.blocks}
        onSelect={partial(this._handleSelect, 0)}
      />
    );
  }

  private _renderChildBlocks() {
    const { block, blocks, renderBlock } = this.props;
    const { clientY } = this.state;
    const distances = this._calculateLineDistances(clientY);
    const nearestIndex = calculateNearestLineIndex(distances);
    const hover = isHovered(this.state);
    return block.getChildren().map((child, i, childBlocks) => {
      return (
        <React.Fragment key={child.getKey()}>
          <InsertLine
            blocks={blocks}
            domRef={partial(this._setLineRef, i)}
            first={i === 0}
            in={lineIn(hover, distances, i)}
            key={`${child.getKey()}-div`}
            nearest={i === nearestIndex}
            onSelect={partial(this._handleSelect, i)}
          />
          {renderBlock(child)}
          {i === childBlocks.size - 1 && (
            <InsertLine
              blocks={blocks}
              domRef={partial(this._setLineRef, i + 1)}
              in={lineIn(hover, distances, i + 1)}
              key={`${child.getKey()}-div-last`}
              last
              nearest={i + 1 === nearestIndex}
              onSelect={partial(this._handleSelect, i + 1)}
            />
          )}
        </React.Fragment>
      );
    });
  }

  private _handleSelect = (index: number, type: string) => {
    const { block, getValue, onChange } = this.props;
    onChange(getValue().insert([block.getKey(), index], type));
  };

  /**
   * Calculates the distance (in y-direction pixels) to each insert line from
   * the passed `clientY` coordinate. Returns an ordered list of these distances.
   */
  private _calculateLineDistances = (clientY?: number): number[] => {
    return this._lines.map(el => {
      if (!el || typeof clientY !== 'number') return Infinity;
      const { top } = el.getBoundingClientRect();
      return Math.abs(clientY - top);
    });
  };

  private _setLineRef = (i: number, el: HTMLElement | null) => {
    this._lines[i] = el;
  };
}
