import { AuthActionType } from './auth-action-type';

export interface LoginSuccessAction {
  accessToken: string;
  expiresIn: number;
  refreshToken?: string;
  tokenType: string;
  type: AuthActionType.LoginSuccess;
}

export const loginSuccess = (
  input: Pick<
    LoginSuccessAction,
    'accessToken' | 'expiresIn' | 'refreshToken' | 'tokenType'
  >,
): LoginSuccessAction => ({
  ...input,
  type: AuthActionType.LoginSuccess,
});
