import { AuthActionType } from './auth-action-type';
import { castArray } from 'lodash';

export interface LoginErrorAction {
  errors: Error[];
  type: AuthActionType.LoginError;
}

export const loginError = (
  errors: Error | readonly Error[],
): LoginErrorAction => ({
  errors: castArray(errors),
  type: AuthActionType.LoginError,
});
