import { RichTextEditorPluginProps, SelectionToolbar } from '@robotsnacks/ui';
import React, { Component } from 'react';

export default class SelectionToolbarPlugin extends Component<
  RichTextEditorPluginProps
> {
  public render() {
    return (
      <SelectionToolbar
        items={[
          ['bold', 'italic', 'underline', 'strike-through'],
          ['align', 'link'],
          [{ 'font-select': ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p'] }],
          ['ordered-list', 'unordered-list'],
          ['undo', 'redo'],
        ]}
        {...this.props as any}
      />
    );
  }
}
