import ImageClient from '@robotsnacks/image-client';
import { CardMedia } from '@robotsnacks/ui';
import React, { Component } from 'react';
import ReadOnlyCardBlockImage from './ReadOnlyCardBlockImage';

import {
  CardBlockBreakpointMediaAttributes,
  CardBlockMediaOrientation,
  CardBlockMediaType,
} from './CardBlockAttributes';

export interface ReadOnlyCardBlockMediaProps {
  imageClient?: ImageClient;
  media: CardBlockBreakpointMediaAttributes;
}

type Props = ReadOnlyCardBlockMediaProps;

type State = {};

export default class CardBlockMedia extends Component<Props, State> {
  render() {
    return (
      <CardMedia style={this._getStyle()}>{this._renderMedia()}</CardMedia>
    );
  }

  private _getStyle() {
    const { height, width, orientation } = this.props.media;
    if (orientation === CardBlockMediaOrientation.Vertical && height && width) {
      return {
        height: `0`,
        width: '100%',
        paddingBottom: `${Math.floor((height / width) * 100)}%`,
        overflow: 'hidden',
      };
    } else {
      return {};
    }
  }

  private _renderMedia() {
    const { media, imageClient } = this.props;
    const { type } = media;
    if (type === CardBlockMediaType.Image) {
      return <ReadOnlyCardBlockImage client={imageClient} media={media} />;
    }
  }
}
