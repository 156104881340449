import React, { SFC } from 'react';

import {
  Container,
  ContainerProps,
  WithStyles,
  createStyles,
  cx,
  withStyles,
} from '@robotsnacks/ui';

const styles = createStyles<'root'>(() => ({
  root: {
    boxSizing: 'border-box',
    position: 'relative',
  },
}));

export type RichTextBlockWrapperProps = ContainerProps;

type Props = WithStyles<RichTextBlockWrapperProps, typeof styles>;

const TitleBlockWrapper: SFC<Props> = ({
  children,
  classes,
  className,
  ...rest
}) => (
  <Container className={cx(className, classes.root)} type="prose" {...rest}>
    {children}
  </Container>
);

TitleBlockWrapper.displayName = 'RichTextBlockWrapper';

export default withStyles(styles)(TitleBlockWrapper);
