import { Redirect, Route } from 'react-router-dom';
import React from 'react';
import { RedirectWithStatusProps } from './redirect-with-status-props';

export const RedirectWithStatus: React.FunctionComponent<
  RedirectWithStatusProps
> = ({ status, from, to }): React.ReactElement | null => {
  return (
    <Route
      render={({ staticContext }) => {
        // there is no `staticContext` on the client, so
        // we need to guard against that here
        if (staticContext) {
          (staticContext as { url?: string }).url = to;
          (staticContext as { status?: number }).status = status;
        }
        return <Redirect from={from} to={to} />;
      }}
    />
  );
};
