import EventEmitter from 'eventemitter3';
import { Message } from './message';
import { Rpc } from './rpc';
import WindowMessenger from '@robotsnacks/window-messenger';

type GetEditorValueFn = () => { value: string; compiledValue: string };

export interface MessageProxyOptions {
  editorKey: string;
  getEditorValue?: GetEditorValueFn;
  getCompiledEditorValue?: any;
  win?: Window | null;
}

export default class MessageProxy extends EventEmitter {
  private _editorKey: string;
  public _messenger: WindowMessenger;
  private _getEditorValue: GetEditorValueFn;
  private _getCompiledEditorValue: any;

  public constructor(options: MessageProxyOptions) {
    super();

    const target = options.win
      ? options.win
      : typeof window !== undefined
      ? window.opener
      : null;

    this._editorKey = options.editorKey;
    this._getEditorValue = options.getEditorValue || ((() => '') as any);
    this._getCompiledEditorValue = options.getCompiledEditorValue;
    this._messenger = new WindowMessenger({
      allowedOrigins: /.*/,
      psk: this._editorKey,
      target,
    })
      .on(Message.AwkEditorSave, this._closeEditor)
      .on('preview', (...d: any[]) => this.emit('preview', ...d))
      .on(Message.SaveEditorValue, (...d: any[]) =>
        this.emit(Message.SaveEditorValue, ...d),
      )
      .on('NOTIFY_OF_EDITOR_CLOSE', (...d: any[]) =>
        this.emit('NOTIFY_OF_EDITOR_CLOSE', ...d),
      )
      .on('UPDATE_VALUE', (...d: any[]) => this.emit('UPDATE_VALUE', ...d))
      .registerProcedure(Rpc.SendEditorValue, this._getEditorValue)
      .registerProcedure(Rpc.CloseEditor, this._closeEditor);
  }

  public async saveEditorValue() {
    this._messenger.send(Message.SaveEditorValue, {
      editorKey: this._editorKey,
      value: this._getEditorValue(),
      compiledValue: await this._getCompiledEditorValue(),
    });
    window.close();
  }

  public notifyOfEditorClose() {
    this._messenger.send(Message.NotifyOfEditorClose);
  }

  public sendPreviewValue(value: string) {
    this._messenger.send('preview', value);
  }

  public sendEditorValue(value: string) {
    this._messenger.send(Message.EditorValue, value);
  }

  private _closeEditor() {
    this.emit('close');
  }
}
