import { RichTextEditorProps } from '@robotsnacks/ui';
import React, { SFC } from 'react';
import CardBlockRichTextEditor from './CardBlockRichTextEditor';

export interface CardBlockBodyProps
  extends Pick<RichTextEditorProps, 'value'> {}

type Props = CardBlockBodyProps;

const ReadOnlyCardBlockBody: SFC<Props> = ({ value }) => (
  <CardBlockRichTextEditor value={value} readOnly />
);

export default ReadOnlyCardBlockBody;
