import React, { Component } from 'react';
import { BlockComponentProps } from '../BlockComponent';
import { BlockPickerProps } from '../BlockPicker';

export interface ReadOnlyStackBlockProps
  extends BlockComponentProps,
    Pick<BlockPickerProps, 'blocks'> {}

type Props = ReadOnlyStackBlockProps;

type State = {
  clientY?: number;
  activeLineIndex?: number;
};

const initialState: State = Object.freeze({});

export default class ReadOnlyStack extends Component<Props, State> {
  state = initialState;

  public render() {
    const { block, renderChildren } = this.props;
    return <div>{renderChildren(block)}</div>;
  }
}
