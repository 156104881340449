import {
  Action,
  DeepPartial,
  Middleware,
  Reducer,
  Store,
  applyMiddleware,
  createStore as createReduxStore,
} from 'redux';
import { EpicMiddleware, createEpicMiddleware } from 'redux-observable';
import { EpicDependencies } from '../epics';
import { History } from 'history';
import { authMiddleware } from '../middleware';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'connected-react-router';

/**
 * Create store options.
 */
export interface CreateStoreOptions<S, A extends Action> {
  /**
   * Epic dependencies object for Redux Observable.
   */
  epicDependencies: EpicDependencies;

  /**
   * History object for Connected React Router.
   */
  history: History;

  middleware?: Middleware[];

  /**
   * The name of the store. This is used by Redux DevTools to distinguish
   * between multiple stores on the same page.
   */
  name: string;

  /**
   * Preloaded state, if any.
   */
  preloadedState?: DeepPartial<S>;

  /**
   * Root redux reducer function.
   */
  rootReducer: Reducer<S, A>;
}

/**
 * Create store helper function create setting-up a client-side redux store that
 * includes middleware for Connected React Router, Redux Devtools, and Redux
 * Observable.
 * @param options Create store options object.
 */
export const createStore = <S, A extends Action>(
  options: CreateStoreOptions<S, A>,
): [Store<S, A>, EpicMiddleware<A>] => {
  const {
    epicDependencies,
    history,
    middleware = [],
    name,
    preloadedState = {},
    rootReducer,
  } = options;

  // Use Redux Devtools if present.
  const composeEnhancers = composeWithDevTools({
    name,
  });

  // Setup our redux-observable epic middleware.
  const epicMiddleware = createEpicMiddleware<A>({
    dependencies: epicDependencies,
  });

  const store = createReduxStore<S, A, {}, {}>(
    rootReducer,
    preloadedState,
    composeEnhancers(
      applyMiddleware(
        epicMiddleware,
        routerMiddleware(history),
        authMiddleware(),
        ...middleware,
      ),
      // applyMiddleware(synchronizeViewportUrlMiddleware()),
    ),
  );

  return [store, epicMiddleware];
};
