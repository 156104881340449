import React from 'react';
import { SvgAssetProps } from './svg-asset-props';
import { cx } from '@mint/ui';
import { kebabCase } from 'lodash';

export const makeSvgAsset = (
  Component: React.FunctionComponent<React.SVGProps<SVGSVGElement>>,
  displayName: string,
): React.FunctionComponent<SvgAssetProps> => {
  const Icon: React.FunctionComponent<SvgAssetProps> = (
    props,
  ): React.ReactElement => {
    return (
      <Component
        {...props}
        className={cx(kebabCase(displayName), props.className)}
      />
    );
  };

  Icon.displayName = displayName;

  return Icon;
};
