import WindowMessenger from '@robotsnacks/window-messenger';

export const createMessengerToViewport = () =>
  new WindowMessenger({
    allowedOrigins: /.*/,
    target: () => {
      const frame = document.getElementById('viewport') as HTMLIFrameElement;
      if (frame) {
        return frame.contentWindow as any;
      }
    },
  });

export const createMessengerToParent = () =>
  new WindowMessenger({
    allowedOrigins: /.*/,
    target: () => window.parent,
  });
