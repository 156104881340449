import { ToolbarSeparator } from '@robotsnacks/ui';
import React, { SFC } from 'react';
import BlockToolbar, { BlockToolbarProps } from '../BlockToolbar';
import DeleteBlockFlyout from '../DeleteBlockFlyout';
import ImageOptionsFlyout from './ImageOptionsFlyout';

type Props = {
  altText?: string;
  format?: string;
  link?: string;
  onDeleteClick?: () => void;
  onFormatChange?: (format: string) => void;
  onLinkChange?: (link: string) => void;
  onAltTextChange?: (altText: string) => void;
  onPinterestDescriptionChange?: (description: string) => void;
  pinterestDescription?: string;
} & Pick<BlockToolbarProps, 'block' | 'parentItems'>;

const TITLE = 'Image';

const ImageBlockToolbar: SFC<Props> = ({
  altText,
  format,
  link,
  onAltTextChange,
  onDeleteClick,
  onFormatChange,
  onLinkChange,
  onPinterestDescriptionChange,
  pinterestDescription,
  ...rest
}) => (
  <BlockToolbar title={TITLE} {...rest}>
    <ImageOptionsFlyout
      onAltTextChange={onAltTextChange}
      onLinkChange={onLinkChange}
      altText={altText}
      link={link}
      onFormatChange={onFormatChange}
      format={format}
      onPinterestDescriptionChange={onPinterestDescriptionChange}
      pinterestDescription={pinterestDescription}
    />
    <ToolbarSeparator />
    <DeleteBlockFlyout onDeleteClick={onDeleteClick} title={TITLE} />
    {/* <ToolbarSeparator /> */}
    {/* <ToolbarButton>
      <Icon>action:settings</Icon>
    </ToolbarButton> */}
  </BlockToolbar>
);

export default ImageBlockToolbar;
