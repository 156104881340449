import isStringPath from './isStringPath';

/**
 * Converts to the passed path to a string path.
 * @param path The path to convert.
 */
export function toStringPath(
  path: string | [string, string] | [string, number],
): string;
export function toStringPath(key: string, index: string | number): string;
export function toStringPath(...args: any[]): string {
  let p;

  if (args.length === 1 && Array.isArray(args[0])) {
    p = args[0].join('.');
  }

  if (args.length === 1 && typeof args[0] === 'string') {
    p = args[0];
  }

  if (args.length === 2) {
    p = args.join('.');
  }

  if (!isStringPath(p)) {
    throw new Error(`Invalid path: ${JSON.stringify(args[0])}`);
  }

  return p;
}

export default toStringPath;
