import { RichTextEditor, random } from '@robotsnacks/ui';
import React, { Component } from 'react';
import { BlockComponentProps } from '../BlockComponent';
import { RichTextBlockAttributes } from './RichTextBlockAttributes';
import RichTextBlockWrapper from './RichTextBlockWrapper';
import getFormats from './getFormats';

export interface ReadOnlyRichTextBlockProps
  extends BlockComponentProps<RichTextBlockAttributes> {}

type Props = ReadOnlyRichTextBlockProps;

export default class ReadOnlyRichTextBlock extends Component<Props> {
  private _key = random();

  public render() {
    const { block } = this.props;
    return (
      <RichTextBlockWrapper id={block.getKey()}>
        <RichTextEditor
          readOnly
          key={this._key}
          formats={getFormats()}
          value={block.getAttribute('html')}
        />
      </RichTextBlockWrapper>
    );
  }
}
