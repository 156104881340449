import {
  HEADER_HEIGHT,
  HEADER_HEIGHT_SCROLLED,
  HEADER_TRANSITION_TIME,
} from '../header';
import React, { SFC } from 'react';
import { WithStyles, createStyles, cx, withStyles } from '@robotsnacks/ui';

const styles = createStyles<'root' | 'scrolled'>(theme => ({
  root: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: HEADER_HEIGHT,
    // minHeight: `100vh`,
    position: 'relative',
    transition: `padding-top ${HEADER_TRANSITION_TIME}`,
  },
  scrolled: {
    paddingTop: HEADER_HEIGHT_SCROLLED,
  },
  customHeader: {
    paddingTop: 0,
  },
}));

export interface MainProps {
  scrolled?: boolean;
  customHeader?: boolean;
}

type Props = WithStyles<MainProps, typeof styles>;

export const Main: SFC<Props> = ({
  children,
  classes,
  customHeader,
  scrolled,
}) => (
  <main
    className={cx(
      classes.root,
      scrolled && classes.scrolled,
      customHeader && classes.customHeader,
    )}
  >
    {children}
  </main>
);

Main.displayName = 'Main';

export default withStyles(styles)(Main);
