import React, { Component } from 'react';
import DecorationsContext from '../DecorationsContext';

type Props = {};

type State = {
  disabled?: boolean;
  key?: symbol | string | null;
};

const initialState: State = Object.freeze({});

const blur = Symbol();
const disable = Symbol();
const enable = Symbol();
const focus = Symbol();
const shouldDecorate = Symbol();

export default class Provider extends Component<Props, State> {
  state = initialState;

  render() {
    const { key, disabled } = this.state;

    const value = {
      blur: this[blur],
      disable: this[disable],
      disabled: !!disabled,
      enable: this[enable],
      enabled: !disabled,
      focus: this[focus],
      focusedKey: key || null,
      shouldDecorate: this[shouldDecorate],
    };

    return (
      <DecorationsContext.Provider value={value}>
        {this.props.children}
      </DecorationsContext.Provider>
    );
  }

  [blur] = (key: symbol | string) => {
    this.setState({ key: null });
  };

  [disable] = () => {
    this.setState({ disabled: true });
  };

  [enable] = () => {
    this.setState({ disabled: false });
  };

  [focus] = (key: symbol | string) => {
    this.setState({ key });
  };

  [shouldDecorate] = (key: symbol | string) => {
    return !this.state.disabled && (!this.state.key || key === this.state.key);
  };
}
