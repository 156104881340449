/* tslint:disable */

import React, { Component, ReactNode } from 'react';
import Block from '../Block';
import { BlockComponentProps } from '../BlockComponent';
import ProductBlock from './ProductBlock';
import ToolbarHover from '../ToolbarHover';
import ToolbarWrapper from '../ToolbarWrapper';
import { SnipcartBlockAttributes } from './SnipcartBlockAttributes';
import SnipcartBlockToolbar from './SnipcartBlockToolbar';
import { EMPTY_ITEM } from './SnipcartOptionsFlyout';

export interface SnipcartBlockProps
  extends BlockComponentProps<SnipcartBlockAttributes> {
  onDelete?: (block: Block<SnipcartBlockAttributes>) => void;
  parentToolbar?: ReactNode;
  parentToolbarItems?: ReactNode;
}

type Props = SnipcartBlockProps;

export default class SnipcartBlock extends Component<Props> {
  public render() {
    const { block, parentToolbar, parentToolbarItems } = this.props;
    const item = block.getAttribute('item');
    return (
      <ToolbarHover block={block}>
        <div style={{ position: 'relative' }}>
          <ToolbarWrapper>
            {parentToolbar}
            <SnipcartBlockToolbar
              block={block}
              onDeleteClick={this._handleDeleteClick}
              parentItems={parentToolbarItems}
              attributes={block.getAttributes()}
              onChange={this._handleAttributesChange}
            />
          </ToolbarWrapper>
          <ProductBlock item={item || EMPTY_ITEM} />
        </div>
      </ToolbarHover>
    );
  }

  private _handleAttributesChange = (attributes: SnipcartBlockAttributes) => {
    const { block, getValue, onChange } = this.props;
    onChange(getValue().replace(block.setAttribute('item', attributes.item)));
  };

  private _handleDeleteClick = () => {
    const { block, getValue, onChange, onDelete } = this.props;
    onChange(getValue().del(block));
    if (onDelete) onDelete(block);
  };
}
