/* tslint:disable */

import ImageClient from '@robotsnacks/image-client';
import { Image, ImageUploader, cx } from '@robotsnacks/ui';
import React, { Component, ReactNode } from 'react';
import Block from '../Block';
import { BlockComponentProps } from '../BlockComponent';
import ToolbarHover from '../ToolbarHover';
import ToolbarWrapper from '../ToolbarWrapper';
import Delta from '../delta';
import { ImageBlockAttributes } from './ImageBlockAttributes';
import ImageBlockToolbar from './ImageBlockToolbar';
import ImageBlockWrapper from './ImageBlockWrapper';
import { getImageHeight, getImageWidth } from './utils';

export interface ImageBlockProps
  extends BlockComponentProps<ImageBlockAttributes> {
  client?: ImageClient;
  defaultPinterestDescription?: string;
  uploadUri?: string;
  uploadOptions?: any;
  onDelete?: (block: Block<ImageBlockAttributes>) => void;
  parentToolbar?: ReactNode;
  parentToolbarItems?: ReactNode;
}

type Props = ImageBlockProps;

export default class ImageBlock extends Component<Props> {
  public render() {
    const {
      block,
      client,
      defaultPinterestDescription,
      parentToolbar,
      parentToolbarItems,
      readonly,
      uploadOptions,
      uploadUri,
    } = this.props;

    const image = this._renderImage();
    if (readonly) return image;

    return (
      <ToolbarHover block={block}>
        <ImageBlockWrapper format={block.getAttribute('_format')}>
          <ToolbarWrapper>
            {parentToolbar}
            <ImageBlockToolbar
              block={block}
              onDeleteClick={this._handleDeleteClick}
              parentItems={parentToolbarItems}
              altText={block.getAttribute('alt') || ''}
              format={block.getAttribute('_format') || 'full_width'}
              link={block.getAttribute('link') || ''}
              onAltTextChange={this._handleAltTextChange}
              onFormatChange={this._handleFormatChange}
              onLinkChange={this._handleLinkChange}
              pinterestDescription={block.getAttribute('pinterestDescription')}
              onPinterestDescriptionChange={
                this._handlePinterestDescriptionChange
              }
            />
          </ToolbarWrapper>
          <ImageUploader
            filename={block.getAttribute('filename')}
            height={300}
            id={block.getAttribute('id')}
            onUploadComplete={this._handleImageUploadComplete}
            style={{
              minHeight: block.getAttribute('id') ? '' : '300px',
              width: '100%',
            }}
            uploadOptions={uploadOptions}
            uploadUri={uploadUri}
            width={800}
          >
            {({ className, onLoad, onTransitionEnd }) => {
              const id = block.getAttribute('id');
              if (!id) return null;
              return (
                <Image
                  alt={block.getAttribute('alt')}
                  className={cx(className)}
                  client={client}
                  filename={block.getAttribute('filename') as string}
                  fit={block.getAttribute('fit') || ('outside' as any)}
                  height={getImageHeight(block)}
                  imageId={id}
                  onLoad={onLoad}
                  onTransitionEnd={onTransitionEnd}
                  pinterestDescription={
                    block.getAttribute('pinterestDescription') ||
                    defaultPinterestDescription
                  }
                  title={block.getAttribute('title')}
                  width={getImageWidth(block)}
                />
              );
            }}
          </ImageUploader>
        </ImageBlockWrapper>
      </ToolbarHover>
    );
  }

  private _handleAltTextChange = (alt: string) => {
    const { block, getValue, onChange } = this.props;
    onChange(getValue().replace(block.setAttribute('alt', alt)));
  };

  private _handlePinterestDescriptionChange = (description: string) => {
    const { block, getValue, onChange } = this.props;
    onChange(
      getValue().replace(
        block.setAttribute('pinterestDescription', description),
      ),
    );
  };

  private _handleFormatChange = (format: string) => {
    const { block, getValue, onChange } = this.props;
    onChange(getValue().replace(block.setAttribute('_format', format)));
  };

  private _handleLinkChange = (link: string) => {
    const { block, getValue, onChange } = this.props;
    onChange(getValue().replace(block.setAttribute('link', link)));
  };

  private _handleImageUploadComplete = (e: any) => {
    const { block, getValue, onChange } = this.props;
    const updatedValue = getValue().apply(
      Delta.replace(block.getKey(), {
        attributes: {
          _format: block.getAttribute('_format'),
          filename: e.filename,
          id: e.id,
          originalWidth: e.width,
          originalHeight: e.height,
        },
      }),
    );
    onChange(updatedValue);
  };

  private _renderImage = () => {
    const { block, client } = this.props;
    return (
      <Image
        alt={block.getAttribute('alt')}
        // className={block.getAttribute('className')}
        filename={block.getAttribute('filename') as string}
        fit={block.getAttribute('fit') || 'inside'}
        height={getImageHeight(block)}
        id={block.getAttribute('id') as string}
        title={block.getAttribute('title')}
        client={client}
        width={getImageWidth(block)}
        pinterestDescription={block.getAttribute('pinterestDescription')}
      />
    );
  };

  private _handleDeleteClick = () => {
    const { block, getValue, onChange, onDelete } = this.props;
    onChange(getValue().del(block));
    if (onDelete) onDelete(block);
  };
}
