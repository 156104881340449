import { changePasswordEpic } from './change-password-epic';
import { combineEpics } from 'redux-observable';
import { loginEpic } from './login-epic';
import { resetPasswordEpic } from './reset-password-epic';

export const authEpic = combineEpics(
  loginEpic,
  changePasswordEpic,
  resetPasswordEpic,
);
