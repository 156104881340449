import { Middleware } from 'redux';
import { createMessengerToParent } from './create-messenger';

export const sendViewportLocationChangeMiddleware = (): Middleware => {
  const messenger = createMessengerToParent();
  messenger.send('viewport-location-change', {
    location: typeof window !== 'undefined' ? window.location.href : '',
  });
  return () => next => action => next(action);
};
