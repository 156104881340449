import { GridView } from '@robotsnacks/ui';
import { BaseGridBlockBreakpointAttributeItem } from './BaseGridBlockAttributes';

export default (
  view: GridView,
  side: 'north' | 'south' | 'east' | 'west',
  matchKey: string,
): Pick<
  BaseGridBlockBreakpointAttributeItem,
  'column' | 'height' | 'width' | 'row'
> => {
  const data = view.engine.getItem(matchKey);
  if (!data) {
    throw new Error(`Item with key ${matchKey} not found in view engine.`);
  }
  const columns = view.columns || 1;
  const { height, width } = data;
  switch (side) {
    case 'west':
      return {
        height,
        width,
        column: data.column,
        row: data.row,
      };
    case 'north':
      return {
        height,
        width,
        column: data.column,
        row: data.row,
      };
    case 'south':
      return {
        height,
        width,
        column: data.column,
        row: data.row + height,
      };
    case 'east':
      const overflows =
        view.engine.flow === 'down' &&
        data.column + data.width + width > columns;
      return {
        height,
        width,
        column: overflows ? data.column : data.column + width,
        row: overflows ? data.row + height : data.row,
      };
    default:
      throw new Error(`Unknown side: ${side}`);
  }
};
