import gql from 'graphql-tag';

export const CREATE_PAGE_MUTATION = gql`
  mutation CreatePage($input: CreatePageInput!) {
    createPage(input: $input) {
      page {
        id
      }
    }
  }
`;
