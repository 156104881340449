import { AuthActionType } from './auth-action-type';
import { castArray } from 'lodash';

export interface ResetPasswordErrorAction {
  errors: Error[];
  type: AuthActionType.ResetPasswordError;
}

export const resetPasswordError = (
  errors: Error | readonly Error[],
): ResetPasswordErrorAction => ({
  errors: castArray(errors),
  type: AuthActionType.ResetPasswordError,
});
