import { BlockTypeDefinition } from '../BlockTypeDefinition';

export type AttributeFactoryMap = { [name: string]: (value?: any) => any };

export type BlockTypeMap = { [name: string]: BlockTypeDefinition };

type Options = {
  attributes: AttributeFactoryMap;
  blockTypes: BlockTypeMap;
};

/**
 * Manager class which helps keep track of the various registered block types,
 * and their attributes, and attribute factories.
 */
export default class BlockManager {
  private _options: Options;

  constructor(opts: Options) {
    this._options = opts;
  }

  /**
   *
   * @param blockType
   * @param attribute
   * @param value
   */
  constructAttribute(blockType: string, attribute: string, value: any) {
    const factory = this._getBlockAttributeFactory(blockType, attribute);
    return factory(value);
  }

  /**
   *
   * @param blockType
   * @param attribute
   */
  checkAttributeExists(blockType: string, attribute: string) {
    this._getBlockAttributeFactory(blockType, attribute);
  }

  /**
   *
   * @param blockType
   * @param attribute
   */
  private _getBlockAttributeFactory(blockType: string, attribute: string) {
    const blockDefinition = this._options.blockTypes[blockType];
    if (!blockDefinition) {
      throw new Error(`Unknown block type "${blockType}".`);
    }
    const { attributes } = blockDefinition;
    if (!attributes) {
      throw new Error(`Block "${blockType}" does not have any attributes.`);
    }
    const attributeDefinition = attributes[attribute];
    if (!attributeDefinition) {
      throw new Error(
        `Block "${blockType}" does not have attribute "${attribute}".`,
      );
    }
    return this._getAttributeFactory(attributeDefinition.type);
  }

  /**
   *
   * @param attributeType
   */
  private _getAttributeFactory(attributeType: string) {
    const factory = this._options.attributes[attributeType];
    if (!factory) throw new Error(`Unknown attribute type "${attributeType}".`);
    return factory;
  }
}
