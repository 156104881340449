import {
  Image,
  ImageUploader,
  RichTextEditor,
  createStyles,
  cx,
  withStyles,
} from '@robotsnacks/ui';
import React, { Component } from 'react';
import { CardSponsor } from './CardBlockAttributes';

const styles = createStyles<'root'>(() => ({
  root: {
    // flex: 1,
    padding: '0.5em',
    position: 'relative',
    '& *:first-child': {
      marginTop: 0,
    },
    '& p': {
      fontSize: '0.85rem',
      fontStyle: 'italic',
      marginTop: '0.2em',
      opacity: 0.8,
    },
  },
}));

class CardBlockSponsor extends Component<any> {
  public render() {
    const {
      block,
      classes,
      imageClient,
      onUploadComplete,
      onTextChange,
      uploadOptions,
      uploadUri,
    } = this.props;
    const sponsor: CardSponsor = block.getAttribute('sponsor');
    return (
      <div
        className={cx(classes.root)}
        style={{
          borderTop: '1px solid rgba(0, 0, 0, 0.1)',
          display: 'flex',
          marginTop: '0.5rem',
          padding: '0.5rem',
        }}
      >
        <div
          style={{
            width: '33%',
            marginRight: '1rem',
          }}
        >
          <ImageUploader
            style={{ minHeight: '50px', position: 'relative' }}
            uploadOptions={uploadOptions}
            uploadUri={uploadUri}
            onUploadComplete={onUploadComplete}
          >
            {({ className, onTransitionEnd, onLoad }) => {
              if (!sponsor.id) return null;
              return (
                <Image
                  client={imageClient}
                  imageId={sponsor.id}
                  filename="sponsor"
                  fit="inside"
                  className={cx(className)}
                  onLoad={onLoad}
                  onTransitionEnd={onTransitionEnd}
                  style={{ width: '100%', height: '100%' }}
                />
              );
            }}
          </ImageUploader>
        </div>
        <div
          style={{
            alignItems: 'center',
            flex: 1,
            position: 'relative',
            display: 'flex',
          }}
        >
          <RichTextEditor
            value={sponsor.html}
            onChange={onTextChange}
            defaultValue="Sponsored by..."
            style={{ flex: 1 }}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(CardBlockSponsor);
