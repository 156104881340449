/* tslint:disable */
import React, { Component } from 'react';
import { BlockComponentProps } from '../BlockComponent';
import ProductBlock from './ProductBlock';
import { SnipcartBlockAttributes } from './SnipcartBlockAttributes';

export interface ReadOnlySnipcartBlockProps
  extends BlockComponentProps<SnipcartBlockAttributes> {}

class ReadOnlySnipcartBlock extends Component<ReadOnlySnipcartBlockProps> {
  public render() {
    const { block } = this.props;
    const item = block.getAttribute('item');
    if (!item) {
      return null;
    } else {
      return <ProductBlock item={item} />;
    }
  }
}

export default ReadOnlySnipcartBlock;
