import { TextBlock } from '@robotsnacks/icons';
import React from 'react';

export default {
  attributes: {
    html: { type: 'identity' },
    ops: { type: 'identity' },
  },
  icon: <TextBlock />,
  name: 'RICHTEXT',
  title: 'Rich Text',
  v: 0,
};
