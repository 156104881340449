import { DeckBlock } from '@robotsnacks/icons';
import React from 'react';

export default {
  attributes: { breakpoints: { type: 'identity' } },
  icon: <DeckBlock />,
  name: 'DECK',
  title: 'Deck',
  v: 0,
};
