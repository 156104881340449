import React, { MouseEventHandler } from 'react';
import { NavLink } from 'react-router-dom';
import { Menu } from './Menu';
// import NavItem from './NavItem';

import {
  Button,
  WithStyles,
  createStyles,
  cx,
  withStyles,
} from '@robotsnacks/ui';

const styles = createStyles<'root' | 'drawerOpen' | 'link' | 'active'>(
  theme => ({
    root: {
      ...theme.font('p'),
      background: theme.color('background.paper'),
      height: '100vh',
      left: 0,
      minWidth: 240,
      maxWidth: 300,
      position: 'fixed',
      top: 0,
      transform: 'translate3d(-100%, 0, 0)',
      transition: [
        'transform 200ms cubic-bezier(0.25, 0.055, 0.015, 1)',
        'box-shadow 200ms',
      ].join(','),
      zIndex: 1010,
    },
    link: {
      textDecoration: 'none',
      color: 'inherit',
      background: 'white',
      padding: '0.5rem',
      userSelect: 'none',
      display: 'block',
      '&$active': {
        background: '#f8f8f8',
      },
    },
    active: {},
    drawerOpen: {
      boxShadow: '0 0 16px rgba(0, 0, 0, 0.3)',
      transform: 'translate3d(0, 0, 0)',
    },
  }),
);

export interface NavProps {
  menu?: Menu;
  onEditClick?: MouseEventHandler;
  onItemClick?: MouseEventHandler;
  visible?: boolean;
  writable?: boolean;
}

type Props = WithStyles<NavProps, typeof styles>;

const Nav: React.StatelessComponent<Props> = ({
  classes,
  menu,
  onEditClick,
  onItemClick,
  visible,
  writable,
}) => {
  const { items = [] } = menu || {};
  return (
    <nav className={cx(classes.root, visible && classes.drawerOpen)}>
      <ul>
        {items.map(item => {
          if (!item.to) return null;
          return (
            <li key={item.id}>
              <NavLink
                onClick={onItemClick}
                to={item.to}
                className={classes.link}
                activeClassName={classes.active}
              >
                {item.title}
              </NavLink>
            </li>
          );
        })}
      </ul>
      {writable && <Button onClick={onEditClick}>Edit Menu</Button>}
    </nav>
  );
};

export default withStyles(styles)(Nav);
