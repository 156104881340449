import {
  ImageCropGravity,
  ImageCropStrategy,
  ImageFit,
  ImageRect,
} from '@robotsnacks/image-client';

export type CardBlockAttributes = {
  to?: string;
  href?: string;
  html?: string;
  expandedHtml?: string;
  breakpoints: { [name: string]: CardBlockBreakpointAttributes };
  sponsor?: CardSponsor;
};

export type CardSponsor = {
  html?: string;
  id?: string;
  show?: boolean;
};

export type CardBlockBreakpointAttributes = {
  media: CardBlockBreakpointMediaAttributes;
};

export enum CardBlockMediaOrientation {
  Horizontal = 'HORIZONTAL',
  Vertical = 'VERTICAL',
}

export enum CardBlockMediaType {
  Image = 'IMAGE',
}

export type CardBlockBreakpointMediaAttributes = CardBlockBreakpointImageAttributes;

export type CardBlockBreakpointImageAttributes = {
  blur?: number;
  className?: string;
  crop?: ImageCropStrategy | ImageCropGravity | ImageCropGravity[];
  filename?: string;
  fit?: ImageFit;
  height: number;
  id?: string;
  lossless?: boolean;
  maxHeight?: number;
  maxWidth?: number;
  minHeight?: number;
  minWidth?: number;
  nearLossless?: boolean;
  orientation?: CardBlockMediaOrientation;
  progressive?: boolean;
  quality?: number;
  rect?: ImageRect;
  type?: CardBlockMediaType.Image;
  width: number;
};
