import { createStyles } from '@robotsnacks/ui';

export default createStyles<
  | 'root'
  | 'date'
  | 'day'
  | 'month'
  | 'year'
  | 'text'
  | 'title'
  | 'titleWrapper'
  | 'time'
  | 'fullDate'
  | 'timezone'
>(theme => ({
  root: {
    display: 'flex',
  },
  text: {
    paddingLeft: '0.7em',
    marginLeft: '0.3rem',
    borderLeft: '1px solid #666',
    overflow: 'hidden',
    paddingTop: 2,
    '& $title': {
      marginTop: 0,
      fontSize: '1.5rem',
      lineHeight: 1,
    },
    '& $time': {
      lineHeight: 1.2,
      marginTop: 0,
      fontWeight: 'bold',
    },
    '& $fullDate': {
      lineHeight: 1.1,
      marginTop: 0,
      fontSize: 12,
    },
  },
  date: {
    display: 'inline-flex',
    flexDirection: 'column',
    ...theme.font('p'),
    lineHeight: 1.5,
    width: '3em',
  },
  day: {
    textAlign: 'center',
    fontSize: 26,
    // padding: '0 6px',
    lineHeight: 1.35,
  },
  month: {
    textAlign: 'center',
    fontSize: 10,
    textTransform: 'uppercase',
    opacity: 0.7,
  },
  year: {
    textAlign: 'center',
    fontSize: 11,
    opacity: 0.7,
  },
  title: {
    height: '1.38em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  timezone: {
    display: 'inline-block',
    fontSize: 10,
    fontWeight: 'normal',
    paddingLeft: 4,
  },
  titleWrapper: {},
  time: {},
  fullDate: {},
}));
