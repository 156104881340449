import { CardBlock } from '@robotsnacks/icons';
import React from 'react';

export default {
  attributes: {
    breakpoints: { type: 'identity' },
    href: { type: 'identity' },
    html: { type: 'identity' },
    expandedHtml: { type: 'identity' },
    ops: { type: 'identity' },
    start: { type: 'identity' },
    to: { type: 'identity' },
    sponsor: { type: 'identity' },
  },
  icon: <CardBlock />,
  name: 'EVENT_CARD',
  title: 'Event Card',
  v: 0,
};
