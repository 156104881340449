import React, { Fragment, useState } from 'react';
import Backdrop from '../backdrop';
import Header from '../header';
import Main from '../main';
import { ThemeProvider as MintThemeProvider } from '@mint/ui';
import { NavContainer } from '../nav';
import { PublicAppProps } from './public-app-props';
import { PublicAppRoutes } from '../public-app-routes';
import { Query } from 'react-apollo';
import { admin } from '../../../themes/admin';
import { get } from 'lodash';
import gql from 'graphql-tag';

const getThemeQuery = gql`
  query getTheme {
    site {
      id
      legacyTheme
      legacyLogoImage {
        id
      }
      headerOverrideHtml
      footerOverrideHtml
    }
  }
`;

export const PublicApp: React.FunctionComponent<
  PublicAppProps
> = (): React.ReactElement => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [editingMenu, setEditingMenu] = useState(false);

  return (
    <Query query={getThemeQuery}>
      {({ data, loading }) => {
        if (loading && !get(data, 'site.legacyTheme')) return null;
        return (
          <MintThemeProvider theme={admin}>
            <Fragment>
              {!data.site.headerOverrideHtml && (
                <Header
                  siteId={data.site.id}
                  logoId={
                    data.site.legacyLogoImage && data.site.legacyLogoImage.id
                  }
                  onMenuClick={() => setMenuOpen(true)}
                />
              )}
              {data.site.headerOverrideHtml && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.site.headerOverrideHtml,
                  }}
                />
              )}
              <Main customHeader={!!data.site.headerOverrideHtml}>
                <PublicAppRoutes />
              </Main>
              {data.site.footerOverrideHtml && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.site.footerOverrideHtml,
                  }}
                />
              )}
              {!data.site.headerOverrideHtml && (
                <NavContainer
                  visible={menuOpen || editingMenu}
                  onEditStart={() => setEditingMenu(true)}
                  onEditDone={() => setEditingMenu(false)}
                  onItemClick={() => setMenuOpen(false)}
                />
              )}
              <Backdrop
                visible={menuOpen || editingMenu}
                onClick={() => setMenuOpen(false)}
              />
            </Fragment>
          </MintThemeProvider>
        );
      }}
    </Query>
  );
};

PublicApp.displayName = 'PublicApp';
