/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { AdminAppAction, AuthActionType, LoginSuccessAction } from '../actions';
import { Middleware } from 'redux';
import cookies from 'js-cookie';
import { persistAuth } from '../persist';

const isLoginSuccessAction = (
  action: AdminAppAction,
): action is LoginSuccessAction => action.type === AuthActionType.LoginSuccess;

export const authMiddleware = (): Middleware => store => next => action => {
  if (isLoginSuccessAction(action)) {
    persistAuth(action);
    cookies.set('cs.auth', action.accessToken, {
      expires: new Date(Date.now() + action.expiresIn * 1000),
    });
  }
  return next(action);
};
