import { DateTime, Duration } from 'luxon';
import React, { SFC } from 'react';
import BlockToolbar, { BlockToolbarProps } from '../BlockToolbar';
import DeleteBlockFlyout from '../DeleteBlockFlyout';
import CardOptionsFlyout from './CardOptionsFlyout';
import EventListFlyout from './EventListFlyout';

interface Props extends Pick<BlockToolbarProps, 'block' | 'parentItems'> {
  onDeleteClick?: () => void;
  eventList?: any[];
  onEventSelect?: (event: any) => void;
  onShowSponsorChange?: (showSponsor: boolean) => void;
  showSponsor?: boolean;
}

const TITLE = 'Event Card';

const EventCardBlockToolbar: SFC<Props> = ({
  onDeleteClick,
  eventList,
  onEventSelect,
  showSponsor,
  onShowSponsorChange,
  ...rest
}) => (
  <BlockToolbar title={TITLE} {...rest}>
    <EventListFlyout
      href={(rest as any).block.getAttribute('href')}
      onSelect={onEventSelect}
    />
    <CardOptionsFlyout
      showSponsor={showSponsor}
      onShowSponsorChange={onShowSponsorChange}
    />
    <DeleteBlockFlyout onDeleteClick={onDeleteClick} title={TITLE} />
  </BlockToolbar>
);

export default EventCardBlockToolbar;
