import { Typography } from '@robotsnacks/ui';
import { debounce } from 'lodash';
import React, { Component, ReactNode } from 'react';
import Block from '../Block';
import { BlockComponentProps } from '../BlockComponent';
import ToolbarHover from '../ToolbarHover';
import ToolbarWrapper from '../ToolbarWrapper';
import { TitleBlockAttributes } from './TitleBlockAttributes';
import TitleToolbar from './TitleBlockToolbar';
import TitleBlockWrapper from './TitleBlockWrapper';

const HTML_DEBOUNCE = 500;
const HTML_MAX_WAIT = 2000;

export interface TitleBlockProps
  extends BlockComponentProps<TitleBlockAttributes> {
  onDelete?: (block: Block<TitleBlockAttributes>) => void;
  parentToolbar?: ReactNode;
  parentToolbarItems?: ReactNode;
}

type Props = TitleBlockProps;

interface State {
  html?: string;
}

const initialState: State = Object.freeze({});

export default class TitleBlock extends Component<Props, State> {
  static displayName = 'TitleBlock';
  state = initialState;

  private timePicker: React.Ref<HTMLElement> = React.createRef<HTMLElement>();

  // TODO: We need to update the value when the text editor unmounts, but we
  // can't do this because if the block is deleted, then trying to update it
  // with throw an error.
  // componentWillUnmount() {
  //   this._triggerChange();
  // }

  public render() {
    const { block, parentToolbar, parentToolbarItems } = this.props;
    return (
      <ToolbarHover block={block}>
        <TitleBlockWrapper id={block.getKey()}>
          <ToolbarWrapper>
            {parentToolbar}
            <TitleToolbar
              block={block}
              onDeleteClick={this._handleDeleteClick}
              parentItems={parentToolbarItems}
            />
          </ToolbarWrapper>
          <Typography>
            <input
              className="h1 page__title"
              onChange={this.handleTitleChange}
              placeholder={'Page Title'}
              style={{
                border: 0,
                display: 'block',
                outline: 0,
                padding: 0,
                marginBottom: 0,
                width: '100%',
              }}
              value={block.getAttribute('title') || ''}
            />
            <time>
              <input
                className="p page__published-at"
                onChange={this.handlePublishedAtChange}
                placeholder={'Published Date'}
                style={{
                  border: 0,
                  display: 'block',
                  marginTop: 0,
                  outline: 0,
                  padding: 0,
                  width: '100%',
                }}
                value={block.getAttribute('timestamp') || ''}
              />
            </time>
          </Typography>
        </TitleBlockWrapper>
      </ToolbarHover>
    );
  }

  private handleTitleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { block, getValue, onChange } = this.props;
    onChange(
      getValue().replace(
        block.setAttribute('title', (e.target as HTMLInputElement).value),
      ),
    );
  };

  private handlePublishedAtChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { block, getValue, onChange } = this.props;
    onChange(
      getValue().replace(
        block.setAttribute('timestamp', (e.target as HTMLInputElement).value),
      ),
    );
  };

  // private _handleTextChange = debounce(
  //   (html: string) => {
  //     const { block, getValue, onChange } = this.props;
  //     onChange(getValue().replace(block.setAttribute('html', html)));
  //   },
  //   HTML_DEBOUNCE,
  //   { leading: false, trailing: true, maxWait: HTML_MAX_WAIT },
  // );

  // private _handleChange = (html: string) => {
  //   this.setState({ html }, this._debouncedTriggerChange);
  // };

  // private _triggerChange = () => {
  //   const { block, getValue, onChange } = this.props;
  //   const updatedBlock = block.setAttribute('html', this.state.html || '');
  //   onChange(getValue().replace(updatedBlock));
  // };

  // private _debouncedTriggerChange = debounce(
  //   this._triggerChange,
  //   HTML_DEBOUNCE,
  //   {
  //     leading: false,
  //     trailing: true,
  //     maxWait: HTML_MAX_WAIT,
  //   },
  // );

  private _handleDeleteClick = () => {
    const { block, getValue, onChange, onDelete } = this.props;
    onChange(getValue().del(block));
    if (onDelete) onDelete(block);
  };
}
