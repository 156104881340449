/* tslint:disable variable-name */

import { Button } from '@robotsnacks/ui';
import React, { Component } from 'react';

class FeedBuilderPopup extends Component<any, any> {
  public state: any = {
    afterType: 'today',
    cql: '',
    excludeTags: '',
    flow: 'down',
    withTags: '',
  };

  public render() {
    return (
      <div
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
        style={{ padding: '0.5rem', width: '240px' }}
      >
        <div style={{ margin: '0.5rem 0', position: 'relative' }}>
          <p>Feed Layout</p>
          <select
            style={{ boxSizing: 'border-box', width: '100%' }}
            onChange={e => this.setState({ flow: e.target.value })}
            value={this.state.flow}
          >
            <option value="down">Show as Grid</option>
            <option value="right">Show as Deck</option>
            <option value="list">Show as List</option>
          </select>
        </div>
        <div style={{ margin: '0.5rem 0', position: 'relative' }}>
          <p>Query</p>
          <input
            type="text"
            style={{ boxSizing: 'border-box', width: '100%' }}
            onChange={e => this.setState({ cql: e.target.value })}
            value={this.state.cql}
          />
        </div>
        <div style={{ margin: '0.5rem 0', position: 'relative' }}>
          <p>Show Events After</p>
          <select
            style={{ boxSizing: 'border-box', width: '100%' }}
            onChange={e => this.setState({ afterType: e.target.value })}
            value={this.state.afterType}
          >
            <option value="today">Today</option>
            <option value="specificDate">Specific Date</option>
          </select>
        </div>
        {this.state.afterType === 'specificDate' && (
          <div style={{ margin: '0.5rem 0', position: 'relative' }}>
            <input
              type="date"
              style={{ boxSizing: 'border-box', width: '100%' }}
              onChange={e => this.setState({ after: e.target.value })}
            />
          </div>
        )}
        <div style={{ margin: '0.5rem 0', position: 'relative' }}>
          <p>Show Events Before</p>
          <input
            type="date"
            style={{ boxSizing: 'border-box', width: '100%' }}
            onChange={e => this.setState({ before: e.target.value })}
          />
        </div>
        <div style={{ margin: '0.5rem 0', position: 'relative' }}>
          <p>Show Events With Tags</p>
          <input
            type="text"
            style={{ boxSizing: 'border-box', width: '100%' }}
            onChange={e => this.setState({ withTags: e.target.value })}
          />
        </div>
        <div style={{ margin: '0.5rem 0', position: 'relative' }}>
          <p>Exclude Events With Tags</p>
          <input
            type="text"
            style={{ boxSizing: 'border-box', width: '100%' }}
            onChange={e => this.setState({ excludeTags: e.target.value })}
          />
        </div>
        <div style={{ marginBottom: '0.5rem' }}>
          <Button onClick={this._handleSaveClick}>Save</Button>
        </div>
      </div>
    );
  }

  private _handleSaveClick = () => {
    this.props.onSaveClick({
      after: this.state.afterType === 'today' ? 'today' : this.state.after,
      before: this.state.before,
      cql: this.state.cql,
      excludeTags: this.state.excludeTags || '',
      flow: this.state.flow,
      withTags: this.state.withTags || '',
    });
  };
}

export default FeedBuilderPopup;
