import { Typography, WithStyles, withStyles } from '@robotsnacks/ui';
import { DateTime, Duration } from 'luxon';
import React, { Component } from 'react';
import { BlockComponentProps } from '../BlockComponent';
import { EventBlockAttributes } from './EventBlockAttributes';
import EventBlockWrapper from './EventBlockWrapper';
import style from './style';

export interface ReadOnlyEventBlockProps
  extends BlockComponentProps<EventBlockAttributes> {}

type Props = WithStyles<ReadOnlyEventBlockProps, typeof style>;

class ReadOnlyEventBlock extends Component<Props, {}> {
  public render() {
    const { block } = this.props;
    return (
      <EventBlockWrapper id={block.getKey()}>
        {this.renderEvent()}
      </EventBlockWrapper>
    );
  }

  private renderEvent() {
    const { block, classes } = this.props;
    const d = block.getAttribute('duration');
    const title = block.getAttribute('title');
    const start = DateTime.fromISO(block.getAttribute('start'));
    const duration = d ? Duration.fromISO(d) : null;
    return (
      <Typography className={classes.root}>
        <div className={classes.date}>
          <span className={classes.month}>{start.monthShort}</span>
          <span className={classes.day}>{start.day}</span>
          <span className={classes.year}>{start.year}</span>
        </div>
        <div className={classes.text}>
          <h2 className={classes.title}>{title}</h2>
          <p className={classes.fullDate}>{start.toFormat('DDDD')}</p>
          <p className={classes.time}>
            {start.toLocaleString(DateTime.TIME_SIMPLE)}
            {duration && (
              <>
                &mdash;{' '}
                {start.plus(duration).toLocaleString(DateTime.TIME_SIMPLE)}
                <span className={classes.timezone}>
                  {start.toFormat('ZZZZ')}
                </span>
              </>
            )}
          </p>
        </div>
      </Typography>
    );
  }
}

export default withStyles(style)(ReadOnlyEventBlock);
