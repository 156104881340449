import { ToolbarSeparator } from '@robotsnacks/ui';
import React, { SFC } from 'react';
import BlockToolbar, { BlockToolbarProps } from '../BlockToolbar';
import DeleteBlockFlyout from '../DeleteBlockFlyout';
import FeedOptionsFlyout from './FeedOptionsFlyout';

type Props = { onDeleteClick?: () => void } & Pick<
  BlockToolbarProps,
  'block' | 'parentItems'
> & {
    initiated?: boolean;
    cql?: string;
    onCqlChange?: (cql: string) => void;
  };

const TITLE = 'Feed';

const FeedBlockToolbar: SFC<Props> = ({
  cql,
  onDeleteClick,
  onCqlChange,
  initiated,
  ...rest
}) => (
  <BlockToolbar title={TITLE} {...rest}>
    {initiated && (
      <>
        <FeedOptionsFlyout cql={cql} onCqlChange={onCqlChange} />
        <ToolbarSeparator />
      </>
    )}
    <DeleteBlockFlyout onDeleteClick={onDeleteClick} title={TITLE} />
  </BlockToolbar>
);

FeedBlockToolbar.displayName = 'RichTextBlockToolbar';

export default FeedBlockToolbar;
