import React, { Component } from 'react';
import { ReadOnlyCardBlock, ReadOnlyCardBlockProps } from '../CardBlock';
import EventDateTime from './EventDateTime';

export default class ReadOnlyEventCardBlock extends Component<
  ReadOnlyCardBlockProps
> {
  public render() {
    const { ...rest } = this.props;
    const block: any = this.props.block;
    return (
      <ReadOnlyCardBlock className="cs-card--event" {...rest}>
        {block.getAttribute('start') && (
          <div
            style={{
              alignItems: 'center',
              borderRight: '1px solid rgba(0, 0, 0, 0.15)',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              padding: '0 0.5rem 0 0.35rem',
            }}
          >
            <EventDateTime block={this.props.block} />
          </div>
        )}
      </ReadOnlyCardBlock>
    );
  }
}
