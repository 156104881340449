import { noop } from 'lodash';
import { createContext } from 'react';
import Block from '../Block';

export type ToolbarContextType = {
  blur: (block: Block) => void;
  focus: (block: Block) => void;
  isIn: (block: Block) => boolean;
  mouseOut: (block: Block) => void;
  mouseOver: (block: Block) => void;
};

export default createContext<ToolbarContextType>({
  blur: noop,
  focus: noop,
  isIn: () => false,
  mouseOut: noop,
  mouseOver: noop,
});
