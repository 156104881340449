import React, { SFC } from 'react';

import {
  Container,
  ContainerProps,
  WithStyles,
  createStyles,
  cx,
  withStyles,
} from '@robotsnacks/ui';

const styles = createStyles<'root'>(() => ({
  root: {
    boxSizing: 'border-box',
    position: 'relative',
  },
}));

export type RichTextBlockWrapperProps = ContainerProps;

type Props = WithStyles<RichTextBlockWrapperProps, typeof styles>;

const RichTextBlockWrapper: SFC<Props> = ({
  children,
  classes,
  className,
  ...rest
}) => (
  <Container
    className={cx(className, classes.root, 'cs-rich-text')}
    type="prose"
    {...rest}
  >
    {children}
  </Container>
);

RichTextBlockWrapper.displayName = 'RichTextBlockWrapper';

export default withStyles(styles)(RichTextBlockWrapper);
