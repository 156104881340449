import ImageClient from '@robotsnacks/image-client';
import { Image } from '@robotsnacks/ui';
import React, { Component } from 'react';
import {
  CardBlockBreakpointImageAttributes,
  CardBlockMediaOrientation,
} from './CardBlockAttributes';

export interface ReadOnlyCardBlockImageProps {
  client?: ImageClient;
  media: CardBlockBreakpointImageAttributes;
}

type Props = ReadOnlyCardBlockImageProps;

export default class ReadOnlyCardBlockImage extends Component<Props> {
  public static defaultProps = {
    blockSizes: [],
  };

  public render() {
    const { client, media } = this.props;
    if (!media.id) return null;
    return (
      <Image
        disablePinterest
        client={client}
        imageId={media.id as string}
        filename={media.filename as string}
        height={media.height}
        width={media.width}
        fit="cover"
        style={this._getStyle()}
        // sources={this._getPictureSources()}
      />
    );
  }

  private _getStyle() {
    const { height, width, orientation } = this.props.media;
    // TODO: We're manually setting the padding to `0` here to override the
    // default behavior of ImageUploader... which should probably be changed.
    if (orientation === CardBlockMediaOrientation.Vertical) {
      return {
        height: `auto`,
        // minHeight: `${height}px`,
        width: '100%',
        paddingBottom: 0,
      };
    } else {
      return {
        height: `100%`,
        width: `${width}px`,
        paddingBottom: 0,
      };
    }
  }

  private _renderAverageColor() {
    // const { height, width } =
  }

  private _getPictureSources(): any[] {
    return [];
    // return this.props.blockSizes.map(size => ({
    //   ...props,
    //   ...this._calcImageSize(size),
    //   fit: 'crop' as 'crop',
    //   media: size.media,
    // }));
  }
}
