import { ToolbarSeparator } from '@robotsnacks/ui';
import React, { SFC } from 'react';
import BlockToolbar, { BlockToolbarProps } from '../BlockToolbar';
import DeleteBlockFlyout from '../DeleteBlockFlyout';
import { SnipcartBlockAttributes } from './SnipcartBlockAttributes';
import SnipcartOptionsFlyout from './SnipcartOptionsFlyout';

type Props = {
  onDeleteClick?: () => void;
  attributes?: SnipcartBlockAttributes;
  onChange?: (attr: SnipcartBlockAttributes) => void;
} & Pick<BlockToolbarProps, 'block' | 'parentItems'>;

const TITLE = 'Snipcart';

const SnipcartBlockToolbar: SFC<Props> = ({
  onDeleteClick,
  onChange,
  attributes,
  ...rest
}) => (
  <BlockToolbar title={TITLE} {...rest}>
    <SnipcartOptionsFlyout onChange={onChange} attributes={attributes} />
    <ToolbarSeparator />
    <DeleteBlockFlyout onDeleteClick={onDeleteClick} title={TITLE} />
  </BlockToolbar>
);

export default SnipcartBlockToolbar;
