import React, { SFC } from 'react';
import getFormats from './getFormats';

import {
  RichTextEditor,
  RichTextEditorProps,
  WithStyles,
  createStyles,
  cx,
  withStyles,
} from '@robotsnacks/ui';

const styles = createStyles<'root'>(() => ({
  root: {
    flex: 1,
    padding: '0.5em',
    position: 'relative',
    '& *:first-child': {
      marginTop: 0,
    },
  },
}));

export type CardBlockRichTextEditorProps = RichTextEditorProps;
type Props = WithStyles<CardBlockRichTextEditorProps, typeof styles>;

const CardBlockRichTextEditor: SFC<Props> = ({ classes, ...rest }) => (
  <RichTextEditor
    className={cx(classes.root)}
    formats={getFormats()}
    {...rest}
  />
);

export default withStyles(styles)(CardBlockRichTextEditor);
