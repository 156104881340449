export const getApolloCache = (app: 'admin' | 'public') => {
  try {
    switch (app) {
      case 'admin':
        return window.__CARDSETTER_ADMIN_APOLLO_STATE__;
      case 'public':
        return window.__CARDSETTER_PUBLIC_APOLLO_STATE__;
      default:
        return {};
    }
  } catch (e) {
    console.error(`Failed to retrieve cache for app: ${app}`);
    return {};
  }
};
