import { GridBlock } from '@robotsnacks/icons';
import React from 'react';

export default {
  attributes: { breakpoints: { type: 'identity' } },
  icon: <GridBlock />,
  name: 'GRID',
  title: 'Grid',
  v: 0,
};
