import ImageClient from '@robotsnacks/image-client';
import { ResizeEvent, snap } from '@robotsnacks/ui';
import { debounce, identity, merge, noop, pickBy, reduce } from 'lodash';
import React, {
  Component,
  createRef,
  ReactElement,
  ReactNode,
  RefObject,
} from 'react';
import Block from '../Block';
import { BlockComponentProps } from '../BlockComponent';
import ToolbarHover from '../ToolbarHover';
import ToolbarWrapper from '../ToolbarWrapper';
import CardBlockBody from './CardBlockBody';
import CardBlockCard from './CardBlockCard';
import CardBlockMedia from './CardBlockMedia';
import CardBlockSponsor from './CardBlockSponsor';
import CardBlockToolbar from './CardBlockToolbar';
import CardBlockWrapper from './CardBlockWrapper';

import {
  CardBlockAttributes,
  CardBlockBreakpointAttributes,
  CardBlockBreakpointMediaAttributes,
  CardBlockMediaOrientation,
  CardBlockMediaType,
} from './CardBlockAttributes';

const SNAP_THRESHOLD = 30;
const HORIZONTAL_THRESHOLD = 200;
const HTML_DEBOUNCE = 500;
const HTML_MAX_WAIT = 2000;

type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T[P] extends ReadonlyArray<infer V>
    ? ReadonlyArray<DeepPartial<V>>
    : DeepPartial<T[P]>;
};

export interface CardBlockProps
  extends BlockComponentProps<CardBlockAttributes> {
  defaults: CardBlockAttributes;
  imageClient?: ImageClient;
  onDelete: (block: Block<CardBlockAttributes>) => void;
  parentToolbar?: ReactNode;
  parentToolbarItems?: ReactNode;
  pageList?: Array<{
    description?: string;
    id: string;
    imageId?: string;
    title: string;
    to: string;
  }>;
  uploadUri?: string;
  uploadOptions?: any;
  toolbar?: ReactElement<any>;
  body?: ReactElement<any>;
  // HACK: we use the editor key to force the rich text editor in the body
  // to re-render with new content.
  editorKey?: number;
  className?: string;
}

type Props = CardBlockProps;

type State = {
  // HACK: we use the editor key to force the rich text editor in the body
  // to re-render with new content.
  editorKey?: number;
  mediaHeight?: number;
  mediaWidth?: number;
  orientation?: CardBlockMediaOrientation;
  readonly?: boolean;
  expanded?: boolean;
  mediaPreHeight?: number;
  resizing?: boolean;
};

const defaultProps = Object.freeze({
  onDelete: noop,
  className: '',
});

const getInitialState = (props: any): State => ({
  editorKey: props.editorKey || 0,
  mediaHeight: undefined,
  mediaWidth: undefined,
  readonly: false,
  expanded: false,
});

export default class CardBlock extends Component<Props, State> {
  static defaultProps = defaultProps;
  state = getInitialState(this.props);

  hooks = () => ({
    onResize: this._handleCardResize,
    onResizeEnd: this._handleCardResizeEnd,
    onResizeStart: this._handleCardResizeStart,
  });

  private _frameRef: HTMLElement | null = null;
  private _textContainerRef: RefObject<HTMLDivElement> = createRef();

  // public shouldComponentUpdate(props: Props) {
  //   return shouldBlockComponentUpdate(props, this.props);
  // }

  componentDidMount() {
    // Check if we have media for the current breakpoint.
    const media = this._getCurrentMedia();
    // If not, force an update so we can measure the DOM.
    if (!media) this.forceUpdate();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      this.props.editorKey !== prevProps.editorKey &&
      this.state.editorKey !== this.props.editorKey
    ) {
      this.setState({ editorKey: this.props.editorKey });
    }
  }

  render() {
    const {
      block,
      imageClient,
      pageList,
      parentToolbar,
      parentToolbarItems,
      uploadUri,
      uploadOptions,
    } = this.props;
    const sponsor = block.getAttribute('sponsor');
    const media = this._getCurrentMedia();
    // TODO: Right now, we're guessing on the orientation for SSR... we need
    // to add dynamic media-query-based classes or inline styles to support
    // proper SSR.
    return (
      <ToolbarHover block={block}>
        <CardBlockWrapper
          className={['cs-card', this.props.className].join(' ').trim()}
          style={{
            cursor: block.getAttribute('expandedHtml') ? 'pointer' : undefined,
          }}
          id={block.getKey()}
          onClick={() => {
            this.setState({ expanded: !this.state.expanded });
          }}
        >
          <ToolbarWrapper>
            {parentToolbar}
            {this._getToolbar()}
          </ToolbarWrapper>
          <CardBlockCard
            orientation={media.orientation}
            domRef={this._setFrameRef}
            id={block.getKey()}
          >
            {this._renderCardMedia()}
            <div style={{ display: 'flex', flex: 1 }}>
              {this.props.children}
              <div
                style={{ flex: 1, display: 'flex', flexDirection: 'column' }}
              >
                <div style={{ flex: 1 }}>
                  <div ref={this._textContainerRef}>{this._getBody()}</div>
                </div>
                {sponsor && sponsor.show && (
                  <CardBlockSponsor
                    block={block}
                    onChange={this.props.onChange}
                    onUploadComplete={this._handleSponsorImageUploadComplete}
                    imageClient={imageClient}
                    uploadOptions={uploadOptions}
                    uploadUri={uploadUri}
                    onTextChange={this._handleSponsorTextChange}
                  />
                )}
              </div>
            </div>
          </CardBlockCard>
        </CardBlockWrapper>
      </ToolbarHover>
    );
  }

  // HACK: public
  setImage(imageId: any, block: any = this.props.block) {
    const update = {
      media: {
        id: imageId,
        type: CardBlockMediaType.Image,
        filename: 'thumbnail',
      },
    };

    const breakpoints = this._getBreakpoints();
    const updatedAttribute = reduce(
      breakpoints,
      (acc, breakpoint, name) => ({
        ...acc,
        [name]: merge(breakpoint, update),
      }),
      {},
    );

    return block.setAttribute('breakpoints', updatedAttribute);
  }

  private _getToolbar() {
    const { block, pageList, parentToolbarItems, toolbar } = this.props;
    if (toolbar) {
      return toolbar;
    }
    const sponsor = block.getAttribute('sponsor');
    const showSponsor = sponsor ? sponsor.show : false;
    return (
      <CardBlockToolbar
        block={block}
        onLinkSelect={this._handleLinkSelect}
        onDeleteClick={this._handleDeleteClick}
        parentItems={parentToolbarItems}
        showSponsor={showSponsor}
        onShowSponsorChange={this._handleShowSponsorChange}
      />
    );
  }

  private _handleShowSponsorChange = (show: boolean) => {
    const { block, getValue, onChange } = this.props;
    const sponsor = {
      ...block.getAttribute('sponsor'),
      show,
    };
    onChange(getValue().replace(block.setAttribute('sponsor', sponsor)));
  };

  private _handleSponsorImageUploadComplete = (result: any) => {
    const { block, onChange, getValue } = this.props;

    const sponsor = {
      ...block.getAttribute('sponsor'),
      id: result.id,
      filename: result.filename,
    };

    const updatedBlock = block.setAttribute('sponsor', sponsor);
    onChange(getValue().replace(updatedBlock));
  };

  private _handleSponsorTextChange = debounce(
    (html: string) => {
      const { block, getValue, onChange } = this.props;
      const sponsor = block.getAttribute('sponsor');
      onChange(
        getValue().replace(
          block.setAttribute('sponsor', {
            ...sponsor,
            html,
          }),
        ),
      );
    },
    HTML_DEBOUNCE,
    { leading: false, trailing: true, maxWait: HTML_MAX_WAIT },
  );

  private _getBody() {
    const { block, body } = this.props;
    if (body) {
      return body;
    }
    const html =
      this.state.expanded && block.getAttribute('expandedHtml')
        ? block.getAttribute('expandedHtml')
        : block.getAttribute('html');
    return (
      <CardBlockBody
        key={this.state.editorKey}
        // resizing={this.props.resizing}
        onChange={this._handleTextChange}
        value={html}
      />
    );
  }

  private _handleTextChange = debounce(
    (html: string) => {
      const { block, getValue, onChange } = this.props;
      onChange(getValue().replace(block.setAttribute('html', html)));
    },
    HTML_DEBOUNCE,
    { leading: false, trailing: true, maxWait: HTML_MAX_WAIT },
  );

  /* ---------------------------------------------------------------------------
   * Child Rendering Methods
   * ------------------------------------------------------------------------ */

  private _renderCardMedia() {
    const { imageClient, uploadUri, uploadOptions } = this.props;
    const { resizing } = this.state;
    return (
      <CardBlockMedia
        media={this._getCurrentCardBreakpoint().media}
        onUploadComplete={this._handleImageUploadComplete}
        onResize={this._handleMediaResize}
        onResizeEnd={this._handleMediaResizeEnd}
        imageClient={imageClient}
        uploadOptions={uploadOptions}
        uploadUri={uploadUri}
        resizing={resizing}
      />
    );
  }

  /* ---------------------------------------------------------------------------
   * Resize Handlers
   * ------------------------------------------------------------------------ */

  private _handleCardResizeStart = () => {
    const { height } = this._getCurrentMedia();
    this.setState({ mediaPreHeight: height, resizing: true });
  };

  private _handleCardResize = () => {
    const rect = (this._frameRef as HTMLElement).getBoundingClientRect();
    const textHeight = this._textContainerRef.current!.getBoundingClientRect()
      .height;
    if (rect.height < HORIZONTAL_THRESHOLD) {
      this.setState({
        mediaHeight: rect.height,
        mediaWidth: rect.height,
        orientation: CardBlockMediaOrientation.Horizontal,
      });
    } else {
      this.setState({
        // mediaHeight: snap((2 / 3) * rect.height, SNAP_THRESHOLD),
        mediaHeight: Math.min(
          this.state.mediaPreHeight as number,
          rect.height - textHeight,
        ),
        mediaWidth: rect.width,
        orientation: CardBlockMediaOrientation.Vertical,
      });
    }
  };

  private _handleCardResizeEnd = (e: ResizeEvent) => {
    const rect = (this._frameRef as HTMLElement).getBoundingClientRect();
    const { mediaHeight, orientation } = this.state;
    let height: number;
    let width: number;

    if (orientation === CardBlockMediaOrientation.Horizontal) {
      height = e.height;
      width = e.height;
    } else {
      // height = snap((2 / 3) * rect.height, SNAP_THRESHOLD);
      width = Math.ceil(e.width);
    }

    this.setState(
      {
        mediaHeight: undefined,
        mediaWidth: undefined,
        orientation: undefined,
        resizing: false,
      },
      () => {
        this._mergeCurrentBreakpointAttributes({
          media: { orientation, width, height: mediaHeight },
        });
      },
    );
  };

  private _handleMediaResize = (e: ResizeEvent) => {
    this.setState({
      mediaHeight: e.height,
      mediaWidth: e.width,
    });
  };

  /**
   * Handles when the card is resized externally to this component.
   */
  private _handleMediaResizeEnd = (e: ResizeEvent) => {
    this.setState(
      {
        mediaHeight: undefined,
        mediaWidth: undefined,
      },
      () => {
        // TODO: snap width if horizontally oriented.
        this._mergeCurrentBreakpointAttributes({
          media: {
            height: Math.ceil(e.height), // snap(e.height, SNAP_THRESHOLD),
            width: Math.ceil(e.width),
          },
        });
      },
    );
  };

  /* ---------------------------------------------------------------------------
   * Attribute Getters
   * ------------------------------------------------------------------------ */

  private _getCurrentCardBreakpoint(): CardBlockBreakpointAttributes {
    const { currentBreakpoint } = this.props;
    const { mediaHeight, mediaWidth, orientation } = this.state;
    const breakpoints = this._getBreakpoints();
    const breakpoint = breakpoints[currentBreakpoint];
    if (mediaHeight || mediaWidth) {
      return merge(breakpoint, {
        media: pickBy(
          { height: mediaHeight, width: mediaWidth, orientation },
          identity,
        ),
      });
    }
    return breakpoint;
  }

  private _getCurrentMedia(): CardBlockBreakpointMediaAttributes {
    return this._getCurrentCardBreakpoint().media;
  }

  private _getBreakpoints() {
    const { block, defaults } = this.props;
    return block.getAttribute('breakpoints') || defaults.breakpoints;
  }

  /* ---------------------------------------------------------------------------
   * Attribute Setters
   * ------------------------------------------------------------------------ */

  private _handleLinkSelect = (page: any) => {
    let editorKey = this.state.editorKey || 0;
    const { block, getValue, onChange } = this.props;
    let updatedBlock = block;

    if (typeof page === 'string') {
      updatedBlock = block
        .setAttribute('to', undefined)
        .setAttribute('href', page);
    } else {
      updatedBlock = block
        .setAttribute('href', undefined)
        .setAttribute('to', page.path);

      if (page.title || page.description) {
        updatedBlock = updatedBlock.setAttribute(
          'html',
          `
          <h1>${page.title}</h1>
          <p>${page.description}</p>
          `.trim(),
        );
        editorKey = Math.random();
      }

      const posterImage = page.images.find(
        posterImage => posterImage.type === 'generic',
      );

      // Naively check if we have any images uploaded currently.
      if (posterImage && posterImage.image) {
        updatedBlock = this.setImage(posterImage.image.id, updatedBlock);
      }
    }

    onChange(getValue().replace(updatedBlock));
    this.setState({ editorKey });
  };

  private _mergeCurrentBreakpointAttributes = (
    value: DeepPartial<CardBlockBreakpointAttributes>,
  ) => {
    const { currentBreakpoint } = this.props;
    if (!currentBreakpoint) throw new Error('No current breakpoint.');
    this._mergeBreakpointAttributes(currentBreakpoint, value);
  };

  private _mergeBreakpointAttributes = (
    breakpoint: string,
    value: DeepPartial<CardBlockBreakpointAttributes>,
  ) => {
    const { block, getValue, onChange } = this.props;
    const breakpoints = this._getBreakpoints();
    const updatedAttribute = {
      ...breakpoints,
      [breakpoint]: merge(breakpoints[breakpoint], value),
    };
    const updatedBlock = block.setAttribute('breakpoints', updatedAttribute);
    onChange(getValue().replace(updatedBlock));
  };

  /* ---------------------------------------------------------------------------
   * Change/Delete Handlers
   * ------------------------------------------------------------------------ */

  private _handleImageUploadComplete = (result: any) => {
    const { block, onChange, getValue } = this.props;

    const update = {
      media: {
        id: result.id,
        type: CardBlockMediaType.Image,
        filename: result.filename,
      },
    };

    const breakpoints = this._getBreakpoints();
    const updatedAttribute = reduce(
      breakpoints,
      (acc, breakpoint, name) => ({
        ...acc,
        [name]: merge(breakpoint, update),
      }),
      {},
    );

    const updatedBlock = block.setAttribute('breakpoints', updatedAttribute);
    onChange(getValue().replace(updatedBlock));
  };

  private _handleDeleteClick = () => {
    const { block, getValue, onChange, onDelete } = this.props;
    onChange(getValue().del(block));
    onDelete(block);
  };

  /* ---------------------------------------------------------------------------
   * Refs
   * ------------------------------------------------------------------------ */

  private _setFrameRef = (el: HTMLElement | null) => {
    this._frameRef = el;
  };
}
