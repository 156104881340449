import { Theme, palettes } from '@mint/ui';

const fontFamily = `'Rubik', sans-serif`;

export const admin = Theme.create({
  palette: {
    error: {
      light: palettes.red[300],
      main: palettes.red[500],
      dark: palettes.red[700],
      contrastText: palettes.common.white,
    },
    primary: {
      light: palettes.blue[300],
      main: palettes.blue[500],
      dark: palettes.blue[700],
      contrastText: palettes.common.white,
    },
    warning: {
      light: palettes.deepOrange[300],
      main: palettes.deepOrange[500],
      dark: palettes.deepOrange[700],
      contrastText: palettes.common.white,
    },
  },
  typography: {
    baseline: 1,
    body: {
      fontFamily: `'Open Sans', sans-serif`,
      fontSize: {
        xs: '1rem',
        sm: '1rem',
        md: '1rem',
        lg: '1.15rem',
        xl: '1.35rem',
      },
      fontWeight: 400,
      rvr: {
        xs: 0.75,
        sm: 0.75,
        md: 0.75,
        lg: 1,
        xl: 1.25,
      },
    },
    button: {
      fontFamily,
      fontSize: {
        xs: '0.8rem',
        sm: '0.8rem',
        md: '0.825rem',
        lg: '0.850rem',
        xl: '0.900rem',
      },
      fontWeight: 400,
      rvr: {
        xs: 0.5,
        sm: 0.5,
        md: 0.5,
        lg: 0.75,
        xl: 1,
      },
      lineHeight: 1.75,
    },
    caption: {
      fontFamily,
    },
    control: {
      fontFamily,
      fontSize: {
        xs: '0.900rem',
        sm: '0.900rem',
        md: '0.925rem',
        lg: '0.950rem',
        xl: '0.975rem',
      },
      fontWeight: 400,
      rvr: {
        xs: 0.5,
        sm: 0.5,
        md: 0.5,
        lg: 0.75,
        xl: 1,
      },
      lineHeight: 1.75,
    },
    dense: {
      fontFamily,
      fontSize: {
        xs: '0.8rem',
        sm: '0.8rem',
        md: '0.8rem',
        lg: '0.9rem',
        xl: '1rem',
      },
      lineHeight: 1.6,
    },
    h1: {
      fontFamily,
      fontSize: {
        xs: '2rem',
        sm: '2rem',
        md: '2rem',
        lg: '2rem',
        xl: '2rem',
      },
      letterSpacing: '-0.04em',
      lineHeight: 1.05,
      rvr: {
        xs: 1.5,
        sm: 1.5,
        md: 1.5,
        lg: 1.5,
        xl: 1.5,
      },
      fontWeight: 700,
    },
    h2: {
      fontFamily,
      fontSize: {
        xs: '1.4rem',
        sm: '1.4rem',
        md: '1.4rem',
        lg: '1.4rem',
        xl: '1.4rem',
      },
      letterSpacing: '-0.02em',
      lineHeight: 1,
      rvr: {
        xs: 1.5,
        sm: 1.5,
        md: 1.5,
        lg: 1.5,
        xl: 1.5,
      },
      fontWeight: 500,
    },
    h3: {
      fontFamily,
      fontSize: {
        xs: '2rem',
        sm: '2rem',
        md: '2rem',
        lg: '2.5rem',
        xl: '3rem',
      },
      letterSpacing: '-0.02em',
      lineHeight: 1,
      rvr: {
        xs: 1.5,
        sm: 1.5,
        md: 2,
        lg: 2.5,
        xl: 3,
      },
      fontWeight: 700,
    },
    h4: {
      fontFamily,
      fontSize: {
        xs: '1.5rem',
        sm: '1.5rem',
        md: '1.5rem',
        lg: '2rem',
        xl: '2.5rem',
      },
      letterSpacing: '-0.01em',
      lineHeight: 1,
      rvr: {
        xs: 1.5,
        sm: 1.5,
        md: 2,
        lg: 2.5,
        xl: 3,
      },
      fontWeight: 700,
    },
    h5: {
      fontFamily,
      fontSize: {
        xs: '1.25rem',
        sm: '1.25rem',
        md: '1.25rem',
        lg: '1.5rem',
        xl: '2rem',
      },
      letterSpacing: '0.04em',
      lineHeight: 1,
      rvr: {
        xs: 1.5,
        sm: 1.5,
        md: 2,
        lg: 2.5,
        xl: 3,
      },
      fontWeight: 700,
      textTransform: 'uppercase',
    },
    h6: {
      fontFamily,
      fontSize: {
        xs: '1rem',
        sm: '1rem',
        md: '1rem',
        lg: '1rem',
        xl: '1.5rem',
      },
      letterSpacing: '0.05em',
      lineHeight: 1,
      rvr: {
        xs: 1.5,
        sm: 1.5,
        md: 2,
        lg: 2.5,
        xl: 3,
      },
      fontWeight: 700,
      textTransform: 'uppercase',
    },
    helper: {
      fontFamily,
      fontSize: {
        xs: '0.675rem',
        sm: '0.675rem',
        md: '0.700rem',
        lg: '0.725rem',
        xl: '0.750rem',
      },
      fontWeight: 500,
      lineHeight: 1.5,
    },
    overline: {
      fontFamily,
      fontSize: {
        xs: '0.75rem',
        sm: '0.75rem',
        md: '0.75rem',
        lg: '1rem',
        xl: '1rem',
      },
      rvr: {
        xs: 1,
        sm: 1,
        md: 1,
        lg: 1,
        xl: 1,
      },
      letterSpacing: '0.05em',
      textTransform: 'uppercase',
    },
    subtitle: {
      fontFamily,
      fontSize: {
        xs: '1rem',
        sm: '1rem',
        md: '1rem',
        lg: '1.35rem',
        xl: '1.5rem',
      },
      fontWeight: 600,
      letterSpacing: '0.03em',
      rvr: {
        xs: 1,
        sm: 1,
        md: 1,
        lg: 1,
        xl: 1,
      },
      textTransform: 'uppercase',
    },
  },
});
