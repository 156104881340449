import {
  filter,
  identity,
  isEqual,
  keys,
  map,
  omit,
  pick,
  pickBy,
} from 'lodash';

const PARAMS_MAP = {
  blur: 'blur',
  crop: 'crop',
  density: 'dpr',
  download: 'dl',
  fit: 'fit',
  format: 'fm',
  height: 'h',
  lossless: 'lossless',
  maxHeight: 'max-h',
  maxWidth: 'max-w',
  minHeight: 'min-h',
  minWidth: 'min-w',
  nearLossless: 'near-lossless',
  progressive: 'progressive',
  quality: 'q',
  rect: 'rect',
  width: 'w',
};

export interface ClientOptions {
  basename?: string;
}

export type ImageCropGravity = 'top' | 'right' | 'bottom' | 'left';
export type ImageCropStrategy = 'attention' | 'entropy';
export type ImageFit =
  | 'clip'
  | 'crop'
  | 'fill'
  | 'max'
  | 'min'
  | 'scale'
  | 'contain'
  | 'cover'
  | 'inside';
export type ImageFormat = 'jpeg' | 'png' | 'webp';

export interface ImageRect {
  top: number;
  left: number;
  width: number;
  height: number;
}

export interface ImageQueryParams {
  blur?: number;
  crop?: ImageCropStrategy | ImageCropGravity | ImageCropGravity[];
  density?: number;
  download?: boolean;
  fit?: ImageFit;
  format?: ImageFormat;
  height?: number | string;
  lossless?: boolean;
  maxHeight?: number;
  maxWidth?: number;
  minHeight?: number;
  minWidth?: number;
  nearLossless?: boolean;
  progressive?: boolean;
  quality?: number;
  rect?: ImageRect;
  width?: number | string;
}

export type ImageSrcParams = ImageQueryParams & {
  basename?: string;
  filename?: string;
  imageId?: string;
};

export default class {
  public static paramsEqual(p1: ImageQueryParams, p2: ImageQueryParams) {
    const k = keys(PARAMS_MAP);
    return isEqual(pick(p1, ...k), pick(p2, ...k));
  }

  public static stripParams<T>(input: T & ImageSrcParams): T {
    return omit(
      input,
      ...keys(PARAMS_MAP),
      'imageId',
      'filename',
      'basename',
    ) as any;
  }

  private options: ClientOptions;

  constructor(options: ClientOptions) {
    this.options = options;
  }

  public src(params: ImageSrcParams) {
    const { basename, imageId, filename } = params;

    const q = filter(
      map(pickBy(params, identity), (v, k: keyof typeof PARAMS_MAP) => {
        const param = PARAMS_MAP[k];
        if (!param) {
          return null;
        }
        if (k === 'rect') {
          const { left, top, width, height } = v as ImageRect;
          return [param, [left, top, width, height].join(',')].join('=');
        }
        return [param, typeof v === 'number' ? Math.round(v) : v].join('=');
      }),
    ).join('&');

    const src = filter([
      basename || this.options.basename,
      imageId,
      filename ? encodeURIComponent(filename) : null,
    ]).join('/');

    return `${src}${q ? `?${q}` : ''}`;
  }

  public srcSet(props: ImageSrcParams & { densities: number[] }): string {
    const { densities } = props;
    return densities
      .map(density => {
        const src = this.src({ ...props, density });
        return `${src}${density === 1 ? '' : ` ${density}x`}`;
      })
      .join(', ');
  }
}
