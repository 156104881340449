import { Auth, Pages } from './resources';
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { SiteOptions } from './site-options';
import { fragmentMatcher } from './client/fragment-matcher';

/**
 * Cardsetter site SDK base class.
 */
export class Site {
  /**
   * Default URI to use for the `HttpLink` if no explicit link is specified, or
   * if no URI is given.
   */
  public static DEFAULT_URI = '/graphql';

  /**
   * Creates a new Apollo client instance.
   * @param options
   */
  private static createClient(options: SiteOptions = {}) {
    // Create the link to the API server.
    const link =
      options.link ||
      new HttpLink({
        fetch:
          typeof window !== 'undefined' ? window.fetch : require('node-fetch'),
        uri: options.uri || Site.DEFAULT_URI,
      });

    // Create a cache for our Apollo client.
    const cache = new InMemoryCache({ fragmentMatcher });

    // Restore the cache if one was passed.
    if (options.cache) {
      cache.restore(options.cache);
    }

    return new ApolloClient({ cache, connectToDevTools: true, link });
  }

  public readonly auth: Auth;
  public readonly client: ApolloClient<object>;
  public readonly pages: Pages;

  /**
   * Creates a new `Site` SDK instance.
   * @param options SDK options.
   */
  public constructor(options: SiteOptions = {}) {
    this.client = Site.createClient(options);
    this.auth = new Auth(this.client);
    this.pages = new Pages(this.client);
  }
}
