import { RichTextEditorProps } from '@robotsnacks/ui';
import React, { SFC } from 'react';
import CardBlockRichTextEditor from './CardBlockRichTextEditor';
import CardBodySelectionToolbar from './CardBodySelectionToolbar';

export interface CardBlockBodyProps
  extends Pick<
    RichTextEditorProps,
    'onChange' | 'onBlur' | 'placeholder' | 'value'
  > {}

type Props = CardBlockBodyProps;

const CardBlockBody: SFC<Props> = ({
  onBlur,
  onChange,
  placeholder,
  value,
}) => (
  <CardBlockRichTextEditor
    onBlur={onBlur}
    onChange={onChange}
    placeholder={placeholder}
    plugins={[CardBodySelectionToolbar]}
    value={value}
    formats={[
      'align',
      'bold',
      'italic',
      'link',
      'list',
      'strike',
      'underline',
      'blockquote',
      'header',
      'cs_link',
    ]}
  />
);

export default CardBlockBody;
