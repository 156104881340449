import { get } from 'lodash';

export default (items: any[] = []) =>
  items
    .map(item => {
      switch (item.__typename) {
        case 'Page':
          return {
            description: item.description || '',
            id: item.id,
            imageId: get(item, 'images[0].image.id', null),
            title: item.title || '',
            to: item.path,
          };
        case 'EventOccurrence':
          return {
            description: get(item, 'description', ''),
            id: item.id,
            imageId: get(item, 'images[0].image.id', null),
            start: item.startsAt,
            title: item.name,
            to: get(item, 'page.path', ''),
          };
        default:
          return null;
      }
    })
    .filter(v => v);
