import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';

const introspectionQueryResultData = {
  __schema: {
    types: [
      {
        kind: 'INTERFACE',
        name: 'Node',
        possibleTypes: [
          { name: 'User' },
          { name: 'Image' },
          { name: 'Category' },
          { name: 'Tag' },
          { name: 'Event' },
          { name: 'Page' },
          { name: 'EventOccurrence' },
          { name: 'PlaceLocation' },
          { name: 'Place' },
          { name: 'PageDraft' },
          { name: 'EventDraft' },
          { name: 'EventOccurrenceDraft' },
        ],
      },
      {
        kind: 'UNION',
        name: 'FeedItem',
        possibleTypes: [
          { name: 'Page' },
          { name: 'EventOccurrence' },
          { name: 'PlaceLocation' },
        ],
      },
    ],
  },
};

export const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});
