import { createStyles, cx, withStyles, WithStyles } from '@robotsnacks/ui';
import React from 'react';
import { SnipcartBlockAttributes } from './SnipcartBlockAttributes';

const styles = createStyles<'root'>(theme => ({
  root: {
    '-webkit-appearance': 'none',
    backgroundColor: theme.color('secondary'),
    border: 'none',
    color: theme.color('background'),
    cursor: 'pointer',
    fontFamily: 'sans-serif',
    fontSize: '1rem',
    letterSpacing: '0.5px',
    margin: '1rem 0',
    outline: 'none',
    padding: '0.75em 0.9em 0.6em',
    textTransform: 'uppercase',
    transition: 'box-shadow 200ms',

    '&:hover': {
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.3)',
    },
  },
}));

export type ProductBlockProps = WithStyles<
  SnipcartBlockAttributes,
  typeof styles
>;

const ProductBlock: React.FunctionComponent<ProductBlockProps> = props => {
  const {
    customFields = [],
    itemId,
    itemName,
    itemPrice,
    itemDescription,
  } = props.item;

  const customFieldAttributes = customFields.reduce((curr, customField, i) => {
    const { name, options = [] } = customField;
    curr[`data-item-custom${i}-name`] = name;
    curr[`data-item-custom${i}-required`] = 'true';
    curr[`data-item-custom${i}-options`] = options
      .map(option => {
        let str = option.name || '';
        if (option.priceChange) {
          str += `[${option.priceChange > 0 ? '+' : '-'}${Math.abs(
            option.priceChange,
          )}]`;
        }
        return str;
      })
      .join('|');
    return curr;
  }, {});

  return (
    <button
      className={cx('snipcart-add-item', props.classes.root)}
      data-item-id={itemId}
      data-item-name={itemName}
      data-item-price={itemPrice}
      data-item-url={
        typeof window !== 'undefined' &&
        window.location.origin + window.location.pathname
      }
      data-item-description={itemDescription}
      {...customFieldAttributes}
    >
      Add to Cart
    </button>
  );
};

export default withStyles(styles)(ProductBlock);
