import { Scroller } from '@robotsnacks/ui';
import React, { Component } from 'react';

import {
  ReadOnlyBaseGridBlock,
  ReadOnlyBaseGridBlockProps,
} from '../BaseGridBlock';

export type ReadOnlyDeckBlockProps = ReadOnlyBaseGridBlockProps;

type Props = ReadOnlyDeckBlockProps;

export default class ReadOnlyDeckBlock extends Component<Props> {
  render() {
    return (
      <Scroller className={'cs-deck'} suppressScrollY>
        <ReadOnlyBaseGridBlock {...this.props} />
      </Scroller>
    );
  }
}
