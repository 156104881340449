import { ActionsObservable, ofType } from 'redux-observable';
import {
  AuthActionType,
  ResetPasswordRequestAction,
  resetPasswordError,
  resetPasswordSuccess,
} from '../../actions';
import { EpicDependencies } from '../epic-dependencies';
import { switchMap } from 'rxjs/operators';

export const resetPasswordEpic = (
  action$: ActionsObservable<ResetPasswordRequestAction>,
  state: {},
  { site }: EpicDependencies,
) =>
  action$.pipe(
    ofType(AuthActionType.ResetPasswordRequest),
    switchMap(async action => {
      try {
        await site.auth.resetPassword({ email: action.email });
        return resetPasswordSuccess();
      } catch (e) {
        return resetPasswordError(e);
      }
    }),
  );
