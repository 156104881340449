import React from 'react';
import { Route } from 'react-router-dom';
import { StatusProps } from './status-props';

export const Status: React.FunctionComponent<StatusProps> = ({
  status,
  children,
}): React.ReactElement | null => {
  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) {
          (staticContext as { status?: number }).status = status;
        }
        return children;
      }}
    />
  );
};
