import React, { Component } from 'react';
import { BlockComponentProps } from '../BlockComponent';

import {
  GridItem,
  WithStyles,
  createStyles,
  withStyles,
} from '@robotsnacks/ui';

const styles = createStyles<'root' | 'child'>(() => ({
  root: {
    boxSizing: 'border-box',
    transition: 'box-shadow 300ms',
  },
  child: {
    flex: 1,
    display: 'flex',
    position: 'relative',
  },
}));

export type ReadOnlyGridItemBlockProps = BlockComponentProps;

type Props = WithStyles<ReadOnlyGridItemBlockProps, typeof styles>;

const defaultProps = Object.freeze({});

class ReadOnlyGridItemBlock extends Component<Props> {
  static defaultProps = defaultProps;

  public render() {
    const { classes, block, renderChildren } = this.props;
    return (
      <GridItem
        className={classes.root}
        id={block.getKey()}
        key={block.getKey()}
      >
        <div className={classes.child}>{renderChildren(block)}</div>
      </GridItem>
    );
  }
}

export default withStyles(styles)(ReadOnlyGridItemBlock);
