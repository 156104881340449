// import { ToolbarButton, ToolbarSeparator } from '@robotsnacks/ui';
import React, { SFC } from 'react';
import BlockToolbar, { BlockToolbarProps } from '../BlockToolbar';
import DeleteBlockFlyout from '../DeleteBlockFlyout';

type Props = { onDeleteClick?: () => void } & Pick<
  BlockToolbarProps,
  'block' | 'parentItems'
>;

const TITLE = 'Rich Text';

const TitleBlockToolbar: SFC<Props> = ({ onDeleteClick, ...rest }) => (
  <BlockToolbar title={TITLE} {...rest}>
    <DeleteBlockFlyout onDeleteClick={onDeleteClick} title={TITLE} />
    {/* <ToolbarSeparator /> */}
    {/* <ToolbarButton>
      <Icon>action:settings</Icon>
    </ToolbarButton> */}
  </BlockToolbar>
);

TitleBlockToolbar.displayName = 'RichTextBlockToolbar';

export default TitleBlockToolbar;
