/* eslint-disable @typescript-eslint/no-non-null-assertion */

import {
  Button,
  Sortable,
  SortableContainer,
  WithStyles,
  createStyles,
  cx,
  random,
  withStyles,
} from '@robotsnacks/ui';
import { Menu, MenuItem } from './Menu';
import { find, findIndex } from 'lodash';
import React from 'react';
import WritableNavItem from './WritableNavItem';

const styles = createStyles<'root' | 'drawerOpen' | 'sortable' | 'sorting'>(
  theme => ({
    root: {
      ...theme.font('p'),
      background: theme.color('background.paper'),
      height: '100vh',
      left: 0,
      minWidth: 240,
      maxWidth: 300,
      position: 'fixed',
      top: 0,
      transform: 'translate3d(-100%, 0, 0)',
      transition: [
        'transform 200ms cubic-bezier(0.25, 0.055, 0.015, 1)',
        'box-shadow 200ms',
      ].join(','),
      zIndex: 1010,
      li: {
        transition: 'box-shadow 240ms',
      },
    },
    drawerOpen: {
      boxShadow: '0 0 16px rgba(0, 0, 0, 0.3)',
      transform: 'translate3d(0, 0, 0)',
    },
    sortable: {
      background: 'white',
      padding: '0.5rem',
      userSelect: 'none',
    },
    sorting: {
      boxShadow: '0 0 8px rgba(0, 0, 0, 0.2)',
      position: 'relative',
      zIndex: 10,
    },
  }),
);

export interface WritableNavProps {
  menu?: Menu;
  onSave?: any;
  visible?: boolean;
  pageList: any;
}

type Props = WithStyles<WritableNavProps, typeof styles>;

export interface WritableNavState {
  menu?: Menu;
  order?: string[];
  preventFlyout?: boolean;
  preventSort?: boolean;
}

class WritableNav extends React.Component<Props, WritableNavState> {
  public state: WritableNavState = {
    order: this.props.menu!.items!.map(i => i.id),
    menu: this.props.menu,
  };

  // public componentDidUpdate(prevProps: WritableNavProps) {
  //   if (this.props.menu !== prevProps.menu) {
  //     this.setState({ menu: this.props.menu });
  //   }
  // }

  public render() {
    const { classes, visible } = this.props;
    const { menu = {} } = this.state;

    return (
      <nav className={cx(classes.root, visible && classes.drawerOpen)}>
        <ul>
          <SortableContainer
            order={this.state.order}
            onSortChange={this._handleSortChange}
          >
            {this.state.order!.map(id => (
              <Sortable
                disabled={this.state.preventSort}
                className={classes.sortable}
                sortClassName={classes.sorting}
                sortKey={id}
                key={id}
                tag="li"
              >
                <WritableNavItem
                  menuItem={this._getMenuItem(id)}
                  pageList={this.props.pageList}
                  onFlyoutClose={this._handleFlyoutClose}
                  onFlyoutOpen={this._handleFlyoutOpen}
                  preventFlyout={this.state.preventFlyout}
                  onEditStart={this._handleEditStart}
                  onEditDone={this._handleEditDone}
                  onChange={this._handleMenuItemChance}
                  onDeleteClick={() => this._handleDelete(id)}
                />
              </Sortable>
            ))}
          </SortableContainer>
        </ul>
        <div style={{ marginTop: '1rem' }}>
          <Button onClick={this.handleInsertItemClick}>Insert Item</Button>
          <Button onClick={this.handleSaveClick}>Save</Button>
        </div>
      </nav>
    );
  }

  private _handleMenuItemChance = (menuItem: MenuItem) => {
    const menu = this.state.menu!;
    const idx = findIndex(menu.items, i => i.id === menuItem.id);
    menu.items!.splice(idx, 1, menuItem);
    this.setState({ menu });
  };

  private _handleDelete(id: string) {
    const menu = this.state.menu!;
    const order = this.state.order!;
    const idx = findIndex(menu.items, i => i.id === id);
    menu.items!.splice(idx, 1);
    order.splice(idx, 1);
    this.setState({ menu, order });
  }

  private _getMenuItem(id: any): any {
    return find(this.state.menu!.items, item => item.id === id);
  }

  private _handleSortChange = (order: string[]) => {
    this.setState({ order });
  };

  private handleSaveClick = () => {
    const order = this.state.order!;
    const items = order.reduce(
      (acc, id) => [...acc, this._getMenuItem(id)],
      [] as any,
    );
    this.props.onSave({ items });
  };

  private handleInsertItemClick = () => {
    const menu = this.state.menu!;
    const order = this.state.order!;
    const id = random();
    menu.items!.push({ id, title: 'New Menu Item' });
    order.push(id);
    this.setState({ menu, order });
  };

  private _handleEditStart = () => {
    this.setState({ preventFlyout: true, preventSort: true });
  };

  private _handleEditDone = () => {
    this.setState({ preventFlyout: false, preventSort: false });
  };

  private _handleFlyoutOpen = () => {
    this.setState({ preventFlyout: true });
  };

  private _handleFlyoutClose = () => {
    this.setState({ preventFlyout: false });
  };
}

export default withStyles(styles)(WritableNav);
