import React, { Component } from 'react';
import { CardBlockMediaOrientation } from './CardBlockAttributes';

import {
  Card,
  CardProps,
  WithStyles,
  createStyles,
  cx,
  withStyles,
} from '@robotsnacks/ui';

const styles = createStyles<'root' | 'horizontal' | 'a'>(() => ({
  root: {
    flex: 1,
    margin: 6,
  },
  a: {
    display: 'flex',
    flexDirection: 'column',
    color: 'inherit',
    height: '100%',
    textDecoration: 'none',
  },
  horizontal: {
    flexDirection: 'row',
  },
}));

export interface CardBlockCardProps extends CardProps {
  orientation?: CardBlockMediaOrientation;
  to?: string;
  href?: string;
}

type Props = WithStyles<CardBlockCardProps, typeof styles>;

interface State {
  insideIframe: boolean;
}

class CardBlockCard extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { insideIframe: false };
  }

  componentDidMount() {
    if (window.parent !== window) {
      this.setState({ insideIframe: true });
    }
  }

  render() {
    const {
      children,
      classes,
      id,
      orientation,
      theme,
      to,
      href,
      ...rest
    } = this.props;
    const horizontal = orientation === CardBlockMediaOrientation.Horizontal;

    return (
      <Card className={cx(classes.root)} {...rest}>
        {to && (
          <a
            className={cx(classes.a, horizontal && classes.horizontal)}
            href={this.createTo()}
          >
            {children}
          </a>
        )}
        {href && (
          <a
            className={cx(classes.a, horizontal && classes.horizontal)}
            href={href}
            target="_blank"
          >
            {children}
          </a>
        )}
        {!to && !href && (
          <a className={cx(classes.a, horizontal && classes.horizontal)}>
            {children}
          </a>
        )}
      </Card>
    );
  }

  private createTo(): string {
    const to = this.props.to;
    const insideIframe = this.state.insideIframe;

    if (!insideIframe) {
      return to;
    }

    if (
      typeof window === 'undefined' ||
      typeof URL === 'undefined' ||
      typeof URLSearchParams === 'undefined'
    ) {
      return to;
    }

    const url = new URL(to, window.location.href);

    if (url.origin === window.origin && insideIframe) {
      url.searchParams.append('viewport', 'true');
      return url.toString();
    }

    return to;
  }
}

export default withStyles(styles)(CardBlockCard);
