export default () => [
  'align',
  'bold',
  'italic',
  'link',
  'list',
  'strike',
  'underline',
  'blockquote',
  'header',
];
