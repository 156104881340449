/**
 * Tests if the passed value is an array path.
 * @param val The value to check.
 */
export default (val: unknown): val is [string, string] =>
  Array.isArray(val) &&
  val.length === 2 &&
  typeof val[0] === 'string' &&
  typeof val[1] === 'string' &&
  /^-?[0-9]+$/.test(val[1]);
