import React, { Component } from 'react';

import {
  Grid,
  GridProps,
  WithStyles,
  createStyles,
  cx,
  withStyles,
} from '@robotsnacks/ui';

const styles = createStyles<'root'>(theme => ({
  root: {
    background: theme.color('background'),
    padding: 6,
    position: 'relative',
  },
}));

export interface BaseGridProps extends GridProps {
  gridRef?: (ref: any | null) => void;
}

type Props = WithStyles<BaseGridProps, typeof styles>;

class BaseGrid extends Component<Props> {
  render() {
    const { children, classes, className, gridRef, ...rest } = this.props;
    return (
      <Grid
        className={cx(classes.root, className, 'cs-base-grid')}
        innerRef={gridRef}
        {...rest}
      >
        {children}
      </Grid>
    );
  }
}

export default withStyles(styles)(BaseGrid);
