import { filter } from 'lodash';

export default (block: any): string => {
  const after =
    block.getAttribute('after') && block.getAttribute('after') !== 'today'
      ? new Date(block.getAttribute('after'))
      : '';
  const afterToday = block.getAttribute('after') === 'today';
  const before = block.getAttribute('before')
    ? new Date(block.getAttribute('before'))
    : '';
  const excludeTags = block.getAttribute('excludeTags') || [];
  const withTags = block.getAttribute('withTags') || [];
  const parts = filter([
    ...withTags.map((tag: string) => `tag ~ ${tag}`),
    ...excludeTags.map((tag: string) => `tag !~ ${tag}`),
    afterToday && `start >= 'today'`,
    after &&
      `start >= '${after.getMonth() +
        1}/${after.getDate()}/${after.getFullYear()}'`,
    before &&
      `start <= '${before.getMonth() +
        1}/${before.getDate()}/${before.getFullYear()} + 1d'`,
  ]).join(' and ');
  return `${parts} sort by start`;
};
