import { AdminAppState, AuthState } from '../../state';
import { getAuthAccessToken } from './get-auth-access-token';
import { getAuthExpiresAt } from './get-auth-expires-at';

export const isLoggedIn = (store: AdminAppState): boolean => {
  const accessToken = getAuthAccessToken(store);
  const expiresAt = getAuthExpiresAt(store);
  const now = new Date();
  return !!(accessToken && expiresAt && expiresAt > now);
};
