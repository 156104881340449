import { Redirect, Route, Switch } from 'react-router-dom';
import { AdminLoginPage } from '../../common';
import { PageRoute } from '../page-route';
import React from 'react';
import { SearchPage } from '../search-page';

export const PublicAppRoutes: React.FunctionComponent = (): React.ReactElement => {
  return (
    <Switch>
      <Route exact path="/admin/login" component={AdminLoginPage} />
      <Route exact path="/admin/page" component={PageRoute} />
      <Route path="/admin" children={() => <Redirect to="/admin/login" />} />
      <Route exact path="/search" component={SearchPage} />
      <Route component={PageRoute} />
    </Switch>
  );
};
