import { CardBlock } from '@robotsnacks/icons';
import React from 'react';

export default {
  attributes: {
    after: { type: 'identity' }, // datetime ISO
    before: { type: 'identity' }, // datetime ISO
    cql: { type: 'identity' }, // CQL string
    excludeTags: { type: 'identity' }, // string[]
    flow: { type: 'identity' }, // down/right/list
    type: { type: 'identity' }, // page/event
    withTags: { type: 'identity' }, // string[]
  },
  icon: <CardBlock />,
  name: 'FEED',
  title: 'Feed',
  v: 0,
};
