import gql from 'graphql-tag';

export default gql`
  query resolveFeed($cql: String!) {
    feed(cql: $cql) {
      edges {
        cursor
        node {
          ... on Page {
            id
            title
            description
            images {
              image {
                id
                url
              }
            }
            path
          }
          ... on EventOccurrence {
            id
            startsAt
            duration
            name
            description
            images {
              image {
                id
              }
            }
            page {
              path
            }
          }
        }
      }
    }
  }
`;
