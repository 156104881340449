/**
 * The index which should be used for an insert "append" operation. This special
 * index instructs to insert a new node at the end of the target node's
 * children. (0 elements from the end).
 */
export const APPEND_INDEX = '-0';

/**
 * Path used frequently to append a node to the root for insert operations.
 */
export const APPEND_TO_ROOT_PATH = `.${APPEND_INDEX}`;
