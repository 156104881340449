const getPublicPreloadedState = () => {
  const state = window.__CARDSETTER_PUBLIC_REDUX_STATE__;

  if (state && state.router && state.router.location) {
    state.router.location.hash = window.location.hash;
  }

  return state;
};

export const getPreloadedState = (app: 'admin' | 'public') => {
  try {
    switch (app) {
      case 'admin':
        return window.__CARDSETTER_ADMIN_REDUX_STATE__;
      case 'public':
        return getPublicPreloadedState();
      // return window.__CARDSETTER_PUBLIC_REDUX_STATE__;
    }
  } catch (e) {
    console.error(`No preloaded state found for app: ${app}`);
  }
  return undefined;
};
