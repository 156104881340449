import { cx } from '@robotsnacks/ui';
import React, { Component } from 'react';

import {
  ReadOnlyBaseGridBlock,
  ReadOnlyBaseGridBlockProps,
} from '../BaseGridBlock';

export type ReadOnlyGridBlockProps = ReadOnlyBaseGridBlockProps;

type Props = ReadOnlyGridBlockProps;

export default class ReadOnlyGridBlock extends Component<Props> {
  render() {
    return (
      <ReadOnlyBaseGridBlock
        {...this.props}
        className={cx('cs-grid', this.props.className)}
      />
    );
  }
}
