import React, { SFC } from 'react';
import BlockToolbar, { BlockToolbarProps } from '../BlockToolbar';
import DeleteBlockFlyout from '../DeleteBlockFlyout';

export interface BaseGridBlockToolbarProps
  extends Pick<BlockToolbarProps, 'block'> {
  onDeleteClick?: () => void;
  title?: string;
}

const BaseGridBlockToolbar: SFC<BaseGridBlockToolbarProps> = ({
  onDeleteClick,
  title,
  ...rest
}) => (
  <BlockToolbar title={title} {...rest}>
    <DeleteBlockFlyout onDeleteClick={onDeleteClick} title={title} />
    {/* <ToolbarSeparator />
    <ToolbarButton>
      <Icon>action:settings</Icon>
    </ToolbarButton> */}
  </BlockToolbar>
);

export default BaseGridBlockToolbar;
