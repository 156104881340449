import { cx } from '@robotsnacks/ui';
import { noop, partial } from 'lodash';
import React, { Component } from 'react';
import Block from '../Block';
import { BlockComponentProps } from '../BlockComponent';
import BreakpointContext from '../BreakpointContext';
import BreakpointProvider from '../BreakpointProvider';
import ComposerState from '../ComposerState';
import DecorationsProvider from '../DecorationsProvider';
import ToolbarProvider from '../ToolbarProvider';
import Delta from '../delta';
import { debug } from '../utils';

import BlockManager, {
  AttributeFactoryMap,
  BlockTypeMap,
} from '../BlockManager';

export type BlockComponentMap<
  T extends BlockComponentProps<any | never> = any
> = {
  [type: string]: BlockComponent<T>;
};

import { BlockComponent, BlockComponentHooks } from '../BlockComponent';

export interface ComposerProps {
  attributes: AttributeFactoryMap;
  blockComponents: BlockComponentMap;
  blockProps: { [type: string]: any };
  blockTypes: BlockTypeMap;
  breakpoints?: { [name: string]: string };
  className?: string;
  onChange: (value: ComposerState) => void;
  readonly?: boolean;
  value: ComposerState;
}

type Props = ComposerProps;

type State = {};

const defaultProps = Object.freeze({
  attributes: {},
  blockComponents: {},
  blockProps: {},
  blockTypes: {},
  onChange: noop,
  value: new ComposerState(),
});

const initialState: State = Object.freeze({});

export default class Composer extends Component<Props, State> {
  static defaultProps = defaultProps;
  state = initialState;

  private _blockMap: { [key: string]: BlockComponent } = {};
  private _manager: BlockManager | undefined;

  componentWillMount() {
    this._createManager();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps !== this.props) {
      this._createManager();
    }
  }

  /**
   *
   */
  render() {
    const { breakpoints, className, value } = this.props;
    return (
      <BreakpointProvider breakpoints={breakpoints}>
        <DecorationsProvider>
          <ToolbarProvider>
            <div className={cx('composer', className)}>
              {value.tree.root.children.map(node =>
                this._renderBlock(
                  new Block({
                    node,
                    getManager: this._getManager,
                  }),
                ),
              )}
            </div>
          </ToolbarProvider>
        </DecorationsProvider>
      </BreakpointProvider>
    );
  }

  /**
   * Renders the component for a Paper block.
   * @param block The Paper block to render.
   */
  private _renderBlock = (block: Block<any>, props?: any) => {
    const { blockComponents, onChange, readonly, value } = this.props;
    const { key, type } = block.node;
    const blockType = this.props.blockTypes[type];

    // TODO: maybe render empty block in writable mode?
    if (!blockType) return null;

    const BlkComp = blockComponents[type];
    const blockProps = this.props.blockProps[type];

    debug('Rendering %s block: %O', block.getType(), {
      props,
      block: block.toJS(),
      component: BlkComp,
      value: value.toJS(),
    });

    return (
      <BreakpointContext.Consumer key={key}>
        {({ breakpoints, breakpointNames, current, getMedia }) => (
          <BlkComp
            block={block}
            breakpointNames={breakpointNames}
            breakpoints={breakpoints}
            currentBreakpoint={current}
            delta={new Delta()}
            getBreakpointMedia={getMedia}
            getValue={this._getValue}
            hook={partial(this._runHooks, key)}
            id={key}
            onChange={onChange}
            readonly={readonly}
            ref={partial(this._setBlockRef, key)}
            renderBlock={this._renderBlock}
            renderChildren={this._renderChildren}
            {...blockProps}
            {...props}
          />
        )}
      </BreakpointContext.Consumer>
    );
  };

  private _renderChildren = (block: Block<any>, props?: any) => {
    return block
      .getChildren()
      .map(childBlock => this._renderBlock(childBlock, props));
  };

  private _createManager() {
    this._manager = new BlockManager({
      attributes: this.props.attributes,
      blockTypes: this.props.blockTypes,
    });
  }

  private _getManager = (): BlockManager => this._manager as BlockManager;

  private _getValue = (): ComposerState => this.props.value;

  private _setBlockRef = (key: string, ref: BlockComponent) => {
    this._blockMap[key] = ref;
  };

  private _runHooks = (key: string, hook: keyof BlockComponentHooks) => (
    ...args: any[]
  ) => {
    const node = this._getValue().tree.find(key);
    if (node) {
      node.children.forEach(child => {
        const ref = this._blockMap[child.key];
        if (!ref || !ref.hooks) return;
        let fn: any;
        if (typeof ref.hooks === 'function') {
          fn = ref.hooks()[hook];
        } else {
          fn = ref.hooks[hook];
        }
        if (fn) fn(...args);
      });
    }
  };
}
