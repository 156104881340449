import React, { Component } from 'react';
import { Typography } from '@robotsnacks/ui';
import { BlockComponentProps } from '../BlockComponent';
import { TitleBlockAttributes } from './TitleBlockAttributes';
import TitleBlockWrapper from './TitleBlockWrapper';

export interface ReadOnlyTitleBlockProps
  extends BlockComponentProps<TitleBlockAttributes> {}

type Props = ReadOnlyTitleBlockProps;

export default class ReadOnlyTitleBlock extends Component<Props> {
  public render() {
    const { block } = this.props;
    return (
      <TitleBlockWrapper id={block.getKey()}>
        <Typography>
          <h1 className="page__title" style={{ marginBottom: 0 }}>
            {block.getAttribute('title') || ''}
          </h1>
          <p className="page__published-at" style={{ marginTop: 0 }}>
            {block.getAttribute('timestamp') || ''}
          </p>
        </Typography>
      </TitleBlockWrapper>
    );
  }
}
