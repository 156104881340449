import { Middleware } from 'redux';
import { createMessengerToParent } from './create-messenger';
import { replace } from 'connected-react-router';

export const receiveLocationChangeFromAdmin = (): Middleware => {
  const messenger = createMessengerToParent();

  return store => {
    messenger.on('admin-location-change', ({ url: adminUrl }) => {
      const url = new URL(adminUrl, window.location.href);
      if (window.location.href !== url.toString()) {
        if (url.pathname.startsWith('/admin')) {
          store.dispatch(
            replace({
              pathname: url.pathname,
              search: url.search,
              state: { __remote: true },
            }),
          );
        } else {
          window.location.href = url.toString();
        }
      }
    });
    return next => action => next(action);
  };
};
