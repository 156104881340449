import { TextBlock } from '@robotsnacks/icons';
import React from 'react';

export default {
  attributes: {
    title: { type: 'identity' },
    timestamp: { type: 'identity' },
  },
  icon: <TextBlock />,
  name: 'TITLE',
  title: 'Title',
  v: 0,
};
