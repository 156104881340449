import { cx } from '@robotsnacks/ui';
import React, { Component } from 'react';
import BaseGridBlock, { BaseGridBlockProps } from '../BaseGridBlock';

export type GridBlockProps = BaseGridBlockProps;

export default class GridBlock extends Component<GridBlockProps> {
  render() {
    return (
      <BaseGridBlock title="Grid" {...this.props} className={cx('cs-grid')} />
    );
  }
}
