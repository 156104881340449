import { SerializedError } from '../state';
import { castArray } from 'lodash';

export const serializeErrors = (errors: Error | Error[]): SerializedError[] =>
  castArray(errors).map(
    (err): SerializedError => ({
      message: err.message,
      code: (err as any).code,
    }),
  );
