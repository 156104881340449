import { WithStyles, createStyles, withStyles } from '@robotsnacks/ui';
import React, { Component, HTMLAttributes } from 'react';

const styles = createStyles<'root'>(theme => ({
  root: {
    display: 'flex',
    flex: 1,
    width: '100%',
  },
}));

export interface CardBlockWrapperProps extends HTMLAttributes<HTMLDivElement> {}

type Props = WithStyles<CardBlockWrapperProps, typeof styles>;

class CardBlockWrapper extends Component<Props> {
  render() {
    const { children, classes, id, theme, className, ...rest } = this.props;
    const clsName = [classes.root, this.props.className || ''].join(' ').trim();
    return (
      <div className={clsName} {...rest}>
        {children}
      </div>
    );
  }
}

export default withStyles(styles)(CardBlockWrapper);
